import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { QUERIES } from '../../constants/constants';
import LogoImage from '../../assets/logo.svg';

const AuthLayout = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Logo to="/">
          <Image src={LogoImage} alt="" />
        </Logo>
        <Outlet />
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
`;

const InnerWrapper = styled.div`
  padding-top: 48px;
  margin: 0 auto;
  max-width: 408px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @media ${QUERIES.tabletAndUp} {
    padding: 110px 24px 24px;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 80px;
`;

const Logo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  margin: 0 auto 18px;
  position: relative;
  text-decoration: none;
  text-align: center;
  width: fit-content;
  border-radius: 50%;
`;

export default AuthLayout;
