import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import PhoneInput from '../PhoneInput';
import { QUERIES } from '../../constants/constants';
import {
  otpVerifyApi,
  smsLoginApi,
} from '../../services/auth-service';
import LoginUsingEmail from './LoginUsingEmail';
import SignUp from './SignUp';
import { useAppDispatch } from '../../store/hook';
import { setUserData } from '../../feature/auth/authSlice';

const cookies = new Cookies();

let currentOtpIndex = 0;
const LoginUsingPhoneNumber = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneValidationMessage, setPhoneValidationMessage] =
    useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpValidationMessage, setOtpValidationMessage] =
    useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);

  const inputRef: any = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  const handleOnChange = (event: any, index: any) => {
    const value = event?.target.value;
    if (isNaN(value)) return false;

    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }

    setOtp(newOtp);
  };

  const handleOnKeyDown = (event: any, index: any) => {
    currentOtpIndex = index;

    if (event.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowLeft') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowRight') {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
  };

  const validatePhoneNumber = function () {
    const phoneRegex = new RegExp('^[6-9][0-9]{9}');
    if (!phoneRegex.test(phoneNumber) || phoneNumber === '') {
      setPhoneValidationMessage('Enter your phone number');
    } else {
      setPhoneValidationMessage('');
    }
  };

  const signIn = async () => {
    const payload = {
      phoneNumber: phoneNumber,
    };

    try {
      const response = await smsLoginApi(payload);

      // ERR: handle if status is failed (if service is down or internet is not working)

      if (response.data.success) {
        setShowOtp(true);
      }
    } catch (error: any) {
      console.log(
        '🚀 ~ file: LoginUsingPhoneNumber.tsx:118 ~ signIn ~ error:',
        error
      );
      // ERROR : problem
      // setErrorMessage(error.response.data.message);
      setErrorMessage('Try again after few minutes');
      setShowOtp(false);

      if (error.response.status === 404) {
        setShowSignUp(true);
      }
      // setErrorMessage('User not registered.');
      // if (!error.response.data.success) {
      //   navigate(`/signup}`);
      // }
    }
  };

  const otpVerify = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      otp: otp.join(''), // ERR: if otp value is less than 6 digit don't send req
    };

    try {
      const response = await otpVerifyApi(payload);

      if (response.data.success) {
        cookies.set('token', response?.data?.data.token, {
          path: '/',
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          // httpOnly: true,
          // secure: true,
        });

        // If Otp matches then
        if (response.data.data.passwordEnabled) {
          setShowEmailForm(true);
        } else {
          dispatch(setUserData(response.data.data));
          navigate('/');
        }
      }
    } catch (error: any) {
      setOtpValidationMessage(error.response.data.message);
    }
  };

  const handleSendOtp = (event: any) => {
    event.preventDefault();
    validatePhoneNumber();

    if (!phoneValidationMessage && phoneNumber) {
      console.log('user is signed in');
      signIn();
    }
  };

  const handleVerifyOtp = (event: any) => {
    event.preventDefault();
    console.log(otp.join(''));
    otpVerify();
  };

  return (
    <>
      {!showSignUp ? (
        <>
          {!showEmailForm ? (
            <LoginWrapper>
              <LoginText>Login</LoginText>
              <SubTitle>Enter your phone number to continue</SubTitle>

              <AuthForm>
                <PhoneInput
                  errorMsg={phoneValidationMessage}
                  required={true}
                  label={
                    phoneValidationMessage
                      ? phoneValidationMessage
                      : 'Phone number'
                  }
                  type="tel"
                  value={phoneNumber}
                  onChange={(event: any) => {
                    if (isNaN(event.target.value)) return false;
                    setPhoneValidationMessage('');
                    setPhoneNumber(event.target.value);
                  }}
                  onBlur={() => {
                    if (phoneNumber) {
                      validatePhoneNumber();
                    }
                  }}
                  autoComplete="on"
                  autoFocus={true}
                  maxLength={10}
                />

                {/* TODO: set tranform and opacity to animate otp fields */}
                {showOtp && (
                  <OtpWrapper>
                    {/* TODO: style otp Wrapper */}
                    {otpValidationMessage && otpValidationMessage}
                    <OtpInnerWrapper>
                      <InputsWrapper>
                        {otp.map((_: any, index: any) => {
                          return (
                            <React.Fragment key={index}>
                              <Input
                                ref={
                                  index === activeOtpIndex
                                    ? inputRef
                                    : null
                                }
                                autoFocus={
                                  index === activeOtpIndex
                                    ? inputRef
                                    : null
                                }
                                type="number"
                                inputMode="numeric"
                                maxLength={1}
                                pattern="[a-zA-Z0-9]{1}"
                                value={otp[index]}
                                onChange={(event) =>
                                  handleOnChange(event, index)
                                }
                                onKeyDown={(event) =>
                                  handleOnKeyDown(event, index)
                                }
                                onFocus={(e) => e.target.select()}
                              />
                            </React.Fragment>
                          );
                        })}
                      </InputsWrapper>
                    </OtpInnerWrapper>
                  </OtpWrapper>
                )}

                {errorMessage && (
                  <>
                    <LoginErrorMessage>
                      {errorMessage}
                    </LoginErrorMessage>
                  </>
                )}

                <SubmitButton
                  onClick={showOtp ? handleVerifyOtp : handleSendOtp}
                >
                  {showOtp ? 'VERIFY OTP' : 'LOGIN'}
                </SubmitButton>

                <SignUpLink to="/signup">Sign up</SignUpLink>
                {/* <div
                    style={{ marginTop: '3rem' }}
                    onClick={() => setShowOtp(true)}
                    >
                      {' '}
                      show
                    </div> */}

                {/* <LinkWrapper>
                      <AnchorLink to="/signup">Sign Up</AnchorLink>
                    </LinkWrapper> */}
              </AuthForm>
            </LoginWrapper>
          ) : (
            <LoginUsingEmail></LoginUsingEmail>
          )}
        </>
      ) : (
        <SignUp mobileNumber={phoneNumber} />
      )}
    </>
  );
};

const LoginWrapper = styled.div``;

const LoginText = styled.h4`
  font-size: 20px;
  margin: 2px 0 8px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin: 0%;
  color: #707579;
  line-height: 1.3125;
  text-align: center;
`;

const LoginErrorMessage = styled(SubTitle)`
  display: inline-block;
  text-align: start;
  margin-top: 16px;
  margin-left: 20px;
  color: rgb(218, 28, 28);
  font-size: 16px;
  font-weight: 600;
`;

const SignUpLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: green;
  text-decoration: underline 2px solid green;
`;

const AuthForm = styled.form`
  /* flex: 0; */
  height: 100%;
  width: 100%;
  margin-top: 40px;
  padding: 0px 16px;

  @media ${QUERIES.tabletAndUp} {
    padding: 0;
  }
`;

const OtpWrapper = styled.div`
  text-align: center;
  font-size: 20px;
  margin-top: 16px;
  padding: 7px 6px;
`;

const OtpInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Input = styled.input`
  height: 48px;
  /* width: 48px; */
  width: 15%;
  padding: 0px;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  /* color: rgb(28, 28, 28); */
  color: #1c0080;
  border: 2px solid rgb(207, 207, 207);
  border-radius: 14px;
  appearance: none;
  outline: none;
  appearance: none;
  outline: none;
  transition: all 250ms;
  border: ${(prop: any) => prop.value && '2px solid green'};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    border: 2px solid limegreen;
  }
`;

const SubmitButton = styled.button`
  margin-top: 24px;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  text-align: center;
  color: black;
  background-color: var(--color-cta);
  /* --shadow-color: 145deg 100% 12%;
  box-shadow: 0px 0px 4px -2px hsl(var(--shadow-color)),
    0px 0px 4px -2px hsl(var(--shadow-color)); */

  &:hover {
  }
`;

export default LoginUsingPhoneNumber;
