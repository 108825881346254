import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ELEVATIONS } from '../../constants/elevations';
import Logo from '../Logo/Logo';
import { QUERIES } from '../../constants/constants';
import DesktopNav from './DesktopNav/DesktopNav';
import LoginButton from './LoginButton/LoginButton';
import HeaderLocation from './HeaderLocation/HeaderLocation';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { toggleSearchLocationModal } from '../../feature/SearchLocation/SearchLocationSlice';

const HEADER_HIDE_THRESHOLD = 100;

const Header = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const { isSearchLocationModalOpen } = useAppSelector(
    (state) => state.searchLocation
  );

  // don't delete this
  // React.useEffect(() => {
  //   let previousScrollValue: any;

  //   function handleScroll(e: any) {
  //     const currentScroll = window.scrollY;

  //     if (typeof previousScrollValue !== 'number') {
  //       previousScrollValue = currentScroll;
  //       return;
  //     }

  //     const direction =
  //       currentScroll > previousScrollValue ? 'down' : 'up';

  //     if (
  //       isHeaderVisible &&
  //       direction === 'down' &&
  //       currentScroll > HEADER_HIDE_THRESHOLD
  //     ) {
  //       setIsHeaderVisible(false);
  //     } else if (!isHeaderVisible && direction === 'up') {
  //       setIsHeaderVisible(true);
  //     }

  //     previousScrollValue = currentScroll;
  //   }

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [isHeaderVisible]);

  // const transform = isHeaderVisible
  //   ? 'translateY(0%)'
  //   : 'translateY(-100%)';

  useEffect(() => {
    if (isSearchLocationModalOpen === false) return;

    const handlePopStateListner = () => {
      dispatch(toggleSearchLocationModal(false));
    };

    window.addEventListener('popstate', handlePopStateListner);

    return () => {
      window.removeEventListener('popstate', handlePopStateListner);
    };
  }, [dispatch, isSearchLocationModalOpen]);

  const transform = '';

  return (
    <HeaderWrapper style={{ transform }}>
      <HeaderContent>
        <Logo />
        <LoginButton />
        {location.pathname !== '/checkout' &&
          location.pathname !== '/cart' && <HeaderLocation />}
        <DesktopNav />
      </HeaderContent>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  --shadow-color: 150.31deg 60% 70%;
  /* position: fixed; */ // on listing page
  position: absolute; // only on menu screen
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  will-change: transform;
  transition: transform 350ms ease;
  /* box-shadow: 0px 10px 15px -15px hsla(150.31deg 60% 50%); */
  /* box-shadow: ${ELEVATIONS.medium}; */
  /* border-bottom: 2px solid #9ad4ad; */
  /* border-radius: 0 0 10px 10px; */
  padding: 0 16px;
  max-height: 110px;
  /* height: 60px; */

  @media ${QUERIES.tabletAndUp} {
    height: 80px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  flex-wrap: wrap;
  /* height: 60px; */

  @media ${QUERIES.tabletAndUp} {
    height: 80px;
  }
`;

export default Header;

{
  /* <MobileNav>
          <ShoppingBagButton>
            <Icon id="shopping-bag" />
            <VisuallyHidden>Open cart</VisuallyHidden>
          </ShoppingBagButton>
          <UnstyledButton>
            <Icon id="search" />
            <VisuallyHidden>Search</VisuallyHidden>
          </UnstyledButton>
          <UnstyledButton onClick={() => setShowMobileMenu(true)}>
            <Icon id="menu" />
            <VisuallyHidden>Open menu</VisuallyHidden>
          </UnstyledButton>
        </MobileNav> */
}
