import axios from 'axios';

// const signInUrl = 'http://localhost:4000/api/v1/auth/otp-verify';

const smsLoginApi = async (payload: any) => {
  const signInUrl = 'https://ymiieat.in/api/v1/auth/sms-login';
  const response = await axios.post(signInUrl, payload);
  return response;
};

const otpVerifyApi = async (payload: any) => {
  const verifyUrl = 'https://ymiieat.in/api/v1/auth/otp-verify';
  const response = await axios.post(verifyUrl, payload);
  return response;
};

const emailLoginApi = async (payload: any) => {
  const signInUrl = 'https://ymiieat.in/api/v1/auth/email-login';
  const response = await axios.post(signInUrl, payload);
  return response;
};

const signupApi = async (payload: any) => {
  const signupUrl = 'https://ymiieat.in/api/v1/user/signup';
  const response = await axios.post(signupUrl, payload);
  return response;
};

export { smsLoginApi, emailLoginApi, otpVerifyApi, signupApi };
