import axios from 'axios';

const reverseGeocodeApi = async (lat: any, lng: any) => {
  try {
    const RESTAURANT_SERVICE = 'localhost:5000';

    const { data } = await axios.get(
      // `http://${RESTAURANT_SERVICE}/api/v1/reverse-geocode?lat=${lat}&lng=${lng}`
      `https://ymiieat.in/api/v1/loc/reverse-geocode?lat=${lat}&lng=${lng}`
    );

    return data.data[0];
  } catch (error) {
    console.log(error);
  }
};

// address recomment
const placeIdGeocodeApi = async (placeId: any) => {
  const RESTAURANT_SERVICE = 'localhost:5000';

  const { data } = await axios.get(
    // `http://${RESTAURANT_SERVICE}/api/v1/address-suggestion?placeId=${placeId}`
    `https://ymiieat.in/api/v1/loc/address-suggestion?placeId=${placeId}`
  );

  return data.data[0];
};

// address recomment
const placeAutocompleteApi = async (input: any) => {
  const RESTAURANT_SERVICE = 'localhost:5000';
  const { data } = await axios.get(
    // `http://${RESTAURANT_SERVICE}/api/v1/place-autocomplete?input=${input}`
    `https://ymiieat.in/api/v1/loc/place-autocomplete?input=${input}`
  );

  return data.data;
};

export { reverseGeocodeApi, placeIdGeocodeApi, placeAutocompleteApi };
