import React from 'react';
import styled from 'styled-components';
import { QUERIES } from '../../../constants/constants';
import { Link } from 'react-router-dom';

const LoginButton = () => {
  return (
    <Wrapper>
      <Anchor to="login">Login</Anchor>
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  justify-content: flex-end;
  outline: none;
  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);
  color: black;
  margin-left: auto;
  border-radius: 12px;
  padding: 8px 16px;
  background-color: hsl(0deg 100% 98.35%);
  --shadow-color: 0deg 100% 38.35%;
  box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));

  @media ${QUERIES.tabletAndUp} {
    margin-right: 24px;
    font-size: 14px;
  }

  @media (min-width: 950px) {
    display: none;
  }

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }
`;

const Anchor = styled(Link)`
  text-decoration: none;
  color: black;
`;

export default LoginButton;
