import React, { useEffect } from 'react';
import { ChevronUp } from 'react-feather';
import styled, { keyframes } from 'styled-components';
import useScrollPosition from '../../../hooks/useScrollPosition';
import { X } from 'react-feather';

const LocationPopover = ({ closePopover }: any) => {
  console.log('LocationPopover called');
  const scrollPosition = useScrollPosition();
  console.log(
    '🚀 ~ file: LocationPopover.tsx:10 ~ LocationPopover ~ scrollPosition:',
    scrollPosition
  );

  useEffect(() => {
    if (scrollPosition >= 120) {
      closePopover();
    }
  }, [scrollPosition, closePopover]);

  const handleClose = () => {
    closePopover();
  };

  return (
    <Wrapper>
      <ButtonWrapper>
        <ChevronUpWrapper>
          <ChevronUpIcon>
            <ChevronUp width={20} height={24} strokeWidth={2} />
          </ChevronUpIcon>
        </ChevronUpWrapper>
      </ButtonWrapper>
      <Header>
        <Heading>Add your delivery address</Heading>
        <CloseButton onClick={handleClose}>
          <X size={20} />
        </CloseButton>
      </Header>
      <Para>
        To find out if we can deliver to your location, enter your
        address.
      </Para>
      <CtaText>Set Location</CtaText>
    </Wrapper>
  );
};

const PopoverKeyframe = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  display: block;
  /* margin-top: 8px; */
  top: 100%;
  left: 0;
  animation: ${PopoverKeyframe} 350ms ease-in;
  background-color: #000000e4;
  color: white;
  padding: 16px;
  min-width: 290px;
  max-width: 400px;
  width: 100%;
  z-index: 300;
  border-radius: 14px;
  font-size: 14px;

  @media (min-width: 950px) {
    left: 10%;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: 10%;
  transform: translateY(-100%);
  overflow: hidden;
`;

const ChevronUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 20px;
  background-color: #000000e4;
  border: none;
  color: white;
  transition: transform 0.3s;
  backface-visibility: hidden;
  clip-path: polygon(0% 100%, 50% 10%, 100% 100%);
`;

const SlideUpKeyframe = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0.25;
  }
  to {
    transform: translateY(0%);
  }
`;

const ChevronUpIcon = styled.div`
  line-height: 0;
  padding-top: 12px;
  animation: ${SlideUpKeyframe} 1000ms infinite;

  & * > {
    stroke-linejoin: miter;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.div`
  font-size: 16px;
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 4px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: -10px;
  padding: 20px;
  padding-top: 16px;
  z-index: 99999999999;

  & > svg {
    display: block;
    margin: 0;
    padding: 0;
  }
`;

const Para = styled.div`
  font-size: 14px;
  font-weight: var(--font-weight-regular);
  margin-bottom: 16px;
`;

const CtaText = styled.div`
  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);
  cursor: pointer;
  display: inline-block;
  background-color: #414141;
  padding: 6px 12px;
  border-radius: 8px;
  opacity: 1;
`;

export default React.memo(LocationPopover);
