import React, { useEffect, useState } from 'react';
import { MapPin, Navigation } from 'react-feather';
import styled from 'styled-components';
import LoadingBar from 'react-top-loading-bar';

import useGeoLocation from '../../../hooks/useGeoLocation';
import NotificationPopover from '../../../components/LocationErrorPopover/LocationErrorPopover';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
  setGeoLocationLoadingStatus,
  setLocationError,
} from '../SearchLocationSlice';

const GeoLocation = () => {
  const dispatch = useAppDispatch();
  // TODO : fetch location takes time to fetch GPS Location
  const [progress, setProgress] = useState(0);
  const { fetchLocation, isError, isSuccess } = useGeoLocation();
  console.log(
    '🚀 ~ file: GeoLocation.tsx:19 ~ GeoLocation ~ isSuccess:',
    isSuccess
  );
  console.log(
    '🚀 ~ file: GeoLocation.tsx:19 ~ GeoLocation ~ isError:',
    isError
  );

  const { isGeoLocationLoading } = useAppSelector(
    (state) => state.searchLocation
  );

  const handleGPSButtonClick = () => {
    dispatch(setGeoLocationLoadingStatus(true));
    fetchLocation();
    setProgress(progress + 90);
  };

  useEffect(() => {
    if (isSuccess === false) return;
    setProgress(100);
    history.replaceState(null, '', '/');

    return () => {
      dispatch(setGeoLocationLoadingStatus(false));
    };
  }, [dispatch, isSuccess]);

  // TODO : handle isError if more than 10 second then close modal

  console.log('isGeoLocationLoading', isGeoLocationLoading);

  return (
    <>
      <Wrapper onClick={handleGPSButtonClick}>
        <LoadingBar
          color="#fc6c62"
          progress={progress}
          loaderSpeed={2500}
          height={3}
          shadow={false}
          className="loadingBar"
        />
        <PinIconWrapper>
          <Navigation size={18} />
        </PinIconWrapper>
        {isError ? (
          // <ErrorTextWrapper onClick={handleLocationError}>
          <ErrorTextWrapper>
            You denined us access to your location.
          </ErrorTextWrapper>
        ) : (
          <GPSText>
            {isGeoLocationLoading ? (
              <FetchText>Fetching your location... </FetchText>
            ) : (
              'Locate me using GPS'
            )}
          </GPSText>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #c7c7c7;
  cursor: pointer;
  overflow: hidden;
`;

const PinIconWrapper = styled.div`
  padding-left: 6px;
  display: flex;
  align-items: center;
  height: inherit;
  line-height: 0;
`;

const GPSText = styled.div`
  font-size: 16px;
  padding-left: 16px;
  /* margin: auto 0px; */
  font-weight: var(--font-weight-bold);
  letter-spacing: -0.1px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: green;
  transition: 150ms all;

  &:hover {
    color: #2e862e;
  }
`;

const FetchText = styled(GPSText)`
  color: #3a3a3a;
  font-size: 15px;
  font-weight: 500;
  padding-left: 0px;
`;

const ErrorTextWrapper = styled.div`
  font-size: 14px;
  padding-left: 16px;
  margin: auto 0px;
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.3px;
  overflow: hidden;
  width: 100%;
  color: #e93030ea;
`;

const LoadingBarWrapper = styled.div`
  position: absolute;
  top: 32px;
`;

export default React.memo(GeoLocation);
