import React from 'react';
import styled from 'styled-components';
import { ArrowLeft, X as Close } from 'react-feather';
import { useAppDispatch } from '../../../store/hook';
import { QUERIES } from '../../../constants/constants';
import { toggleSearchLocationModal } from '../SearchLocationSlice';

const SearchBar = ({ searchTerm, setSearchTerm }: any) => {
  const dispatch = useAppDispatch();

  const handleDismiss = () => {
    dispatch(toggleSearchLocationModal(false));
    setSearchTerm('');
    history.replaceState(null, '', '/');
  };

  return (
    <Wrapper>
      <GoToBack onClick={handleDismiss}>
        <ArrowLeft size={18} color="#5c5c5c" />
      </GoToBack>

      <Input
        placeholder="Search for area, street name..."
        value={searchTerm}
        onChange={(e: any) => setSearchTerm(e.target.value)}
        autoFocus={true}
      />
      {searchTerm && (
        <ClearButton onClick={() => setSearchTerm('')}>
          <Close size={18} color="#5c5c5c" />
        </ClearButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  border: 1px solid #dadada;
  box-shadow: rgba(27, 30, 36, 0.06) 0px 0px 4px,
    rgba(27, 30, 36, 0.04) 0px 0px 8px;
  border-radius: 12px;
`;

const Input = styled.input`
  outline: none;
  border: 0;
  width: 100%;
  font-size: 14px;
  border-radius: 12px;
  line-height: 1.8;
  letter-spacing: -0.3px;
  font-weight: var(--font-weight-medium);

  @media ${QUERIES.tabletAndUp} {
    padding-left: 24px;
  }
`;

const Button = styled.button`
  display: block;
  margin: auto 10px;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
  color: inherit;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
`;
const GoToBack = styled(Button)`
  display: block;
  margin: auto 10px;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
  color: inherit;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-right: 14px;

  @media (${QUERIES.tabletAndUp}) {
    display: none;
  }
`;

const ClearButton = styled(Button)``;

export default React.memo(SearchBar);
