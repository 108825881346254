const userLocationUtil = async (data: any) => {
  let area, city, deliveryLocation, primary_text;
  const formatted_address = data?.formatted_address;
  const address = formatted_address
    .split(',')
    .map((item: string) => item.trim());

  const length = address.length;

  if (length === 7 || length === 8) {
    area = address[length - 4];
    city = address[length - 3];
    deliveryLocation =
      address[length - 5] + ',' + ' ' + address[length - 4];
    primary_text = address[length - 4];
  } else if (length === 6) {
    area = address[length - 4];
    city = address[length - 3];
    deliveryLocation = address[length - 4] + address[length - 3];
    primary_text = address[length - 4];
    deliveryLocation =
      address[length - 4] + ',' + ' ' + address[length - 3];
    primary_text = address[length - 4];
  } else if (length === 4 || length === 5) {
    area = '';
    city = address[length - 3];
    deliveryLocation = address[length - 3];
    primary_text = '';
  } else {
    area = '';
    city = address[0];
    deliveryLocation = address[0];
    primary_text = address[0];
  }

  const formattedObj = {
    address: data?.formatted_address,
    lat: data?.geometry.location.lat,
    lng: data?.geometry.location.lng,
    area: area,
    city: city.toLowerCase(),
    deliveryLocation: deliveryLocation,
    primary_text: primary_text,
  };

  return formattedObj;
};

export default userLocationUtil;
