import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface IState {
  data: {
    items: any;
    categories: any;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message: string | null;
  error: null;
}

const initialState: IState = {
  data: {
    categories: [],
    items: {},
  },
  status: 'idle',
  message: null,
  error: null,
};

export const getMenu = createAsyncThunk(
  'menu/getMenu',
  async (menuId: any, thunkAPI: any) => {
    try {
      const { data } = await axios.get(
        // `http://localhost:5000/api/v1/menu?menuId=${menuId}`
        `https://ymiieat.in/api/v1/menu?menuId=${menuId}`
      );

      return data;
    } catch (error: any) {
      console.log(error);

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const menuSlice = createSlice({
  name: 'menuSlice',
  initialState: initialState,
  reducers: {
    setMenu: (state, action) => {
      state.data.items = action.payload.data?.items;
      state.data.categories = action.payload.data?.menu?.categories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenu.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.items = action.payload?.data.items;
        state.data.categories =
          action.payload?.data?.menu?.categories;
      })
      .addCase(getMenu.rejected, (state, action: any) => {
        state.status = 'failed';
        state.data.items = null;
        state.data.categories = null;
        state.error = action.payload;
      });
  },
});

export const { setMenu } = menuSlice.actions;
export default menuSlice.reducer;
