import React, { useEffect } from 'react';
import styled from 'styled-components';
import Shimmer from './Shimmer';
import { useAppSelector } from '../../../store/hook';
import useGetLocalStorage from '../../../hooks/useGetLocalStorage';
import SearchResultRow from './SearchResultRow';

const SearchResult = ({
  searchTerm,
  clearSearchSuggestions,
  isLoading,
  suggestions,
}: any) => {
  const [value] = useGetLocalStorage('recentLocationSearch');

  const { reverseApiStatus, isGeoLocationLoading } = useAppSelector(
    (state) => state.searchLocation
  );

  return (
    <Wrapper>
      {/* {reverseApiStatus === 'loading' && <Shimmer />} */}

      {isGeoLocationLoading && (
        // <div> We are fetching your location</div>
        <Shimmer />
      )}

      {!isLoading && searchTerm && suggestions.length === 0 && (
        <div>No results</div>
      )}

      {isLoading || (!suggestions && searchTerm) ? (
        <Shimmer />
      ) : (
        suggestions?.map((data: any, index: any) => {
          const filteredData = {
            description: data?.description,
            main_text: data?.structured_formatting.main_text,
            secondary_text:
              data?.structured_formatting.secondary_text,
            place_id: data?.place_id,
            terms: data?.terms,
            types: data?.types,
          };

          return (
            <SearchResultRow
              data={filteredData}
              clearSearchSuggestions={clearSearchSuggestions}
              key={index}
            />
          );
        })
      )}

      {/* hide when  */}
      {!(reverseApiStatus === 'loading') &&
        suggestions.length <= 0 &&
        searchTerm.length === 0 &&
        value?.items.length > 0 &&
        isGeoLocationLoading === false && (
          <>
            <Heading>RECENT SEARCHES </Heading>
            {value?.items.map((item: any, index: any) => (
              <SearchResultRow
                data={item}
                clearSearchSuggestions={clearSearchSuggestions}
                key={index}
              />
            ))}
          </>
        )}

      {suggestions.length <= 0 &&
        searchTerm.length === 0 &&
        !value?.items.length &&
        isGeoLocationLoading === false && (
          <div>Show SVG image (search your location) </div>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: block;
`;

const Heading = styled.div`
  font-size: 13px;
  margin: 10px 0;
  font-weight: 500;
  padding-left: 6px;
`;

export default React.memo(SearchResult);
