import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import TextInput from '../TextInput/TextInput';
import styled, { keyframes } from 'styled-components';
import {
  setUserLocation,
  toggleSearchLocationModal,
} from '../../feature/SearchLocation/SearchLocationSlice';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import { Briefcase, Home, MapPin } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

const options = [
  {
    Icon: Home,
    title: 'Home',
  },
  {
    Icon: Briefcase,
    title: 'Work',
  },
  {
    Icon: MapPin,
    title: 'Other',
  },
];

const AddressForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [houseNumber, setHouseNumber] = useState<number>(0);
  const [area, setArea] = useState('');
  const [landmark, setLandmark] = useState('');
  const [annotation, setAnnotation] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [showInput, setShowInput] = useState(false);

  const { data: reverseGeocodeData } = useAppSelector(
    (state) => state.searchLocation
  );
  const reverseGeocodeApiData =
    reverseGeocodeData.reverseGeocodeApiData;

  const handleAddAddress = (event: any) => {
    event.preventDefault();
    console.log('form is clicked');

    // dispatch(saveAddress(......  form  ...... feilds))
    // if all the feilds are not filled then show error and don't submit request.
    if (
      !reverseGeocodeApiData?.address &&
      !houseNumber &&
      !area &&
      !landmark &&
      !annotation
    ) {
      throw new Error('Please enter all the feilds!');
    }

    // setAddress in cookie UserLocation also
    // TODO : call api and save address.

    // if db address addition is successful then save below data otherwise set an
    //  msg : Oops, something went wrong.

    // if address addiotion successful then go to home page

    cookies.set('userLocation', reverseGeocodeApiData, {
      path: '/',
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    });
    localStorage.setItem(
      'ymiieat_userLocation',
      JSON.stringify(reverseGeocodeApiData)
    );
    dispatch(setUserLocation(reverseGeocodeApiData));
    dispatch(toggleSearchLocationModal(false));
    history.replaceState(null, '', '/');
  };

  const handleAnnotationClick = (title: any) => {
    setSelectedTitle(title);

    if (title !== 'Other') {
      setShowInput(false);
      setAnnotation(title);
    }

    if (title === 'Other') {
      setShowInput(true);
      setAnnotation('');
    }
  };

  return (
    <FormWrapper onSubmit={handleAddAddress}>
      <TextInput
        required={true}
        label="Area"
        type="text"
        value={area}
        onChange={(event: any) => {
          setArea(event.target.value);
        }}
        autoComplete="off"
        maxLength={100}
      />

      <TextInput
        required={true}
        data-test-id="login-email-field"
        label="House / Flat no "
        type="number"
        value={houseNumber === 0 ? '' : houseNumber}
        onChange={(event: any) => {
          setHouseNumber(event.target.value);
        }}
        autoComplete="off"
        maxLength={100}
      />

      <TextInput
        required={true}
        label="Landmark"
        type="text"
        value={landmark}
        onChange={(event: any) => {
          setLandmark(event.target.value);
        }}
        autoComplete="off"
        maxLength={100}
      />

      <AnnotationsWrapper>
        <OptionsWrapper>
          {options.map((item: any) => (
            <Option
              onClick={() => handleAnnotationClick(item.title)}
              key={item.title}
              style={{
                backgroundColor:
                  item.title === selectedTitle ? 'black' : '',
                color: item.title === selectedTitle ? 'white' : '',
              }}
            >
              <IconWrapper>
                <item.Icon size={16} />
              </IconWrapper>
              {item.title}
            </Option>
          ))}
        </OptionsWrapper>
        {showInput && (
          <AnnotationInputWrapper>
            <AnnotationInput
              type="text"
              placeholder="my friends home etc..."
              value={annotation}
              onChange={(e: any) => setAnnotation(e.target.value)}
              autoFocus
            />

            <CancelButton
              type="button"
              onClick={() => setShowInput(false)}
            >
              Cancel
            </CancelButton>
          </AnnotationInputWrapper>
        )}
      </AnnotationsWrapper>

      <Button>Submit</Button>
    </FormWrapper>
  );
};

const AnnotationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsl(0, 0%, 95.3%);
  border-radius: 14px;
  overflow: hidden;
  margin-top: 16px;
  border: 1px solid black;
`;

const OptionsWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  height: 52px;
`;

const Option = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  width: 33.3333%;
  margin: 8px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 6px;
  position: relative;
  top: -2px;

  * > svg {
    display: block;
    line-height: 1;
  }
`;

const AnnotationInputWrapper = styled.div`
  --border-color: #302f2f;
  width: 100%;
  position: relative;
  border-top: 2px solid var(--border-color);

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 4px;
    right: 15%;
    border: 2px solid var(--border-color);
    border-right: none;
    border-bottom: none;
    background-color: hsl(0, 0%, 92.3%);
    transform: translateY(-100%) rotate(45deg);
  }
`;

const AnnotationInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  appearance: none;
  border: none;
  background-color: hsl(0, 0%, 92.3%);
  height: 40px;
  padding: 8px 18px;
  font-size: 15px;
  position: relative;
  z-index: 1000000000;
`;

const CancelButton = styled.button`
  position: absolute;
  right: 0;
  padding-right: 14px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: 500;
  color: #e92626;
`;

// ///////////////////////////////////
const FormWrapper = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Button = styled.button`
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 16px;
  height: 48px;
`;

export default AddressForm;
