import {
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import TabsSlice from '../components/Tabs/TabsSlice';
import SearchLocationSlice from '../feature/SearchLocation/SearchLocationSlice';
import ToastSlice from '../feature/toaster/ToastSlice';
import authSlice from '../feature/auth/authSlice';
import cartSlice from '../feature/cart/cartSlice';
import menuSlice from '../feature/menu/menuSlice';

const reducer = {
  auth: authSlice,
  tabs: TabsSlice,
  searchLocation: SearchLocationSlice,
  toasts: ToastSlice,
  cart: cartSlice,
  menu: menuSlice,
};

export const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
