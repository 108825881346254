import { createSlice } from '@reduxjs/toolkit';

interface IState {
  data: {
    toastList: any;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message: string | null;
}

const initialState: IState = {
  data: {
    toastList: [
      {
        id: 323,
        variant: 'success',
        message: 'Hello !',
      },
      {
        id: 569,
        message: 'Logged in',
        variant: 'success',
      },
    ],
  },
  status: 'idle',
  message: null,
};

const ToastSlice = createSlice({
  name: 'ToastSlice',
  initialState: initialState,
  reducers: {
    createToast: (state, action) => {
      state.data.toastList.push({
        id: crypto.randomUUID(),
        message: action.payload.message,
        variant: action.payload.variant,
      });
    },

    dismissToast: (state, action) => {
      const updatedToastList = state.data.toastList.filter(
        (toast: any) => toast.id !== action.payload
      );
      state.data.toastList = updatedToastList;
    },

    DeleteAllToasts: (state) => {
      state.data.toastList = [];
    },
  },
});

export const { createToast, dismissToast, DeleteAllToasts } =
  ToastSlice.actions;

export default ToastSlice.reducer;
