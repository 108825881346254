import { createSlice } from '@reduxjs/toolkit';
import { getToken } from '../../utils/tokenUtils';

interface IState {
  data: {
    userInfo: any;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  message: string | null;
  error: null;
}

const initialState: IState = {
  data: {
    userInfo:
      JSON.parse(localStorage.getItem('userInfo') as any) || {},
  },
  status: 'idle',
  message: null,
  error: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data.userInfo = action.payload;
      localStorage.setItem(
        'userInfo',
        JSON.stringify(action?.payload)
      );

      // if(!action.payload) return;
      //       state.isLoggedIn = (action.payload?.data.token != undefined);
      //       state.data = action.payload?.data.userData;
      //       state.token = action.payload?.data.token;
      //       state.role = action.payload?.data.userData?.userType;
      //       localStorage.setItem('role', action.payload?.data.userData?.userType)
      //       localStorage.setItem('isLoggedIn', (action.payload?.data.token != undefined))
      //       localStorage.setItem('data', JSON.stringify(action.payload?.data.userData))
      //       localStorage.setItem('token', action.payload?.data.token)
    },
  },
});

export const { setUserData } = authSlice.actions;
export default authSlice.reducer;
