import React from 'react';

interface IProps {
  x?: number;
  y?: number;
}

const Spacer = ({ x, y }: IProps) => {
  const styleObj = {
    width: x ? x : '',
    height: y ? y : '',
    minWidth: x ? x : '',
    minHeight: y ? y : '',
  };

  return <div style={styleObj}></div>;
};

export default Spacer;
