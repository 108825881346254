import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled, { keyframes } from 'styled-components';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { QUERIES } from '../../../constants/constants';
import { Crosshair, Minus, Navigation, Plus } from 'react-feather';
import useWindowSize from '../../../hooks/useWindowSize';
import useGetLocalStorage from '../../../hooks/useGetLocalStorage';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import GpsIcon from '../../../assets/GpsIcon.svg';
import axios from 'axios';
import {
  setLocationError,
  setReverseGeocodeApiData,
  setUserLocation,
} from '../../SearchLocation/SearchLocationSlice';
import userLocationUtil from '../../../utils/userLocationUtil';

import { reverseGeocodeApi } from '../../../services';

const containerStyle = {
  width: '100%',
  height: '100%',
};

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;

const GoogleMapContainer = ({ maxWidth = 400 }: any) => {
  const dispatch = useAppDispatch();
  const [value] = useGetLocalStorage('temp_address');
  const windowSize = useWindowSize();

  const [map, setMap] = React.useState(null);
  const [onPress, setOnPress] = useState(false);
  const [isError, setIsError] = useState(false);

  const { data } = useAppSelector((state) => state.searchLocation);
  const userLocation = data.userLocation;

  // TODO: use last saved address lat, lng or

  const center = useMemo<LatLngLiteral>(
    () => ({
      lat: value?.lat || 31.046100000000003,
      lng: value?.lng || 76.70260000000002,
      // lat: userLocation?.lat || value?.lat || 31.046100000000003,
      // lng: userLocation?.lng || value?.lng || 76.70260000000002,
    }),
    [value]
  );

  const options = useMemo<MapOptions>(
    () => ({
      // mapId: 'bcaf31a18bb334c8',
      disableDefaultUI: true,
      zoomControl: false,
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      gestureHandling: `${
        windowSize.width >= 550 ? 'cooperative' : 'greedy'
      }`,
      minZoom: 16,
      maxZoom: 19,
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }),
    [windowSize]
  );

  const reverseGeocodeApiCall = useCallback(
    async (coordinates: any, setUserLocationToRedux: any) => {
      const { lat, lng } = coordinates;

      const data = await reverseGeocodeApi(lat, lng);
      const formattedAddress = await userLocationUtil(data);

      dispatch(setReverseGeocodeApiData(formattedAddress));

      if (setUserLocationToRedux) {
        dispatch(setUserLocation(formattedAddress));
      }

      localStorage.setItem(
        'ymiieat_userLocation',
        JSON.stringify(formattedAddress)
      );
    },
    [dispatch]
  );

  function ZoomControl(map: any) {
    const zoomInButton = document.querySelector(ZoomInButton);
    const zoomOutButton = document.querySelector(ZoomOutButton);

    zoomInButton?.addEventListener('click', function () {
      map.setZoom(map.getZoom() + 1);
    });

    zoomOutButton?.addEventListener('click', function () {
      map.setZoom(map.getZoom() - 1);
    });
  }

  const handleFetchLocation = useCallback(
    (map: any) => {
      const button = document.querySelector(GPSButton);
      button?.addEventListener('click', function () {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position: any) => {
              console.log('Geolocation ', position);

              const svgMarker = {
                url: GpsIcon,
                anchor: new google.maps.Point(10, 10),
              };

              new google.maps.Marker({
                position: {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
                icon: svgMarker,
                map,
              });

              if (position.coords.latitude) {
                map.panTo({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
                map.setZoom(18);

                await reverseGeocodeApiCall(
                  {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                  true
                );
              }
            },
            // TODO: set states to handle errors and success
            (error) => {
              console.log('error', error);

              handleLocationError(true);
            },
            { enableHighAccuracy: true }
          );
        } else {
          setIsError(true);
          handleLocationError(false);
        }

        function handleLocationError(browserHasGeolocation: any) {
          if (browserHasGeolocation) {
            dispatch(
              setLocationError(
                'You denined us access to your location.'
              )
            );
          } else {
            // TODO: make toaster component and show modal at bottom showing the message to the user.
            dispatch(
              setLocationError(
                "Error: Your browser doesn't support geolocation."
              )
            );
          }
        }
      });
    },
    [dispatch, reverseGeocodeApiCall]
  );

  const onLoad = React.useCallback(
    (map: any) => {
      map.addListener('dragend', async () => {
        const mapCenter = map.getCenter();

        const centerLatLng = {
          lat: mapCenter.lat(),
          lng: mapCenter.lng(),
        };

        // new google.maps.Marker({
        //   position: centerLatLng,
        //   map,
        //   title: 'Hello World!',
        // });

        await reverseGeocodeApiCall(centerLatLng, false);
        // check isLocationServiceable from backend on each drag
      });

      map.addListener('zoom_changed', async () => {
        const mapCenter = map.getCenter();

        const centerLatLng = {
          lat: mapCenter.lat(),
          lng: mapCenter.lng(),
        };
      });

      console.log('onLoad called');

      handleFetchLocation(map);
      ZoomControl(map);

      new window.google.maps.LatLngBounds(center);

      setMap(map);
    },
    [handleFetchLocation, reverseGeocodeApiCall, center]
  );

  const onUnmount = useCallback(function callback(map: any) {
    google.maps.event.clearListeners(map, 'dragend');
    google.maps.event.clearListeners(map, 'zoom_changed');
    setMap(null);
  }, []);

  const Enter = () => {
    setOnPress(true);
  };

  const Exit = () => {
    setOnPress(false);
  };

  const pinWrapperStyleObj = {
    transform: onPress
      ? 'translate(-50%,-130%)'
      : 'translate(-50%,-100%)',
  };

  const showDotStyleObj = {
    opacity: onPress ? '1' : '0',
    visibility: onPress ? 'visible' : 'hidden',
  } as any;

  return (
    <Wrapper
      onMouseDown={Enter}
      onMouseUp={Exit}
      onTouchStart={Enter}
      onTouchEnd={Exit}
      maxWidth={maxWidth}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        options={options}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <FixedMarkerWrapper>
          <PinWrapper style={pinWrapperStyleObj}>
            <PinTop>
              <PinTopCircle>
                <Dot></Dot>
              </PinTopCircle>
            </PinTop>
            <PinBottom></PinBottom>
          </PinWrapper>
          <CircleOnMapPress style={showDotStyleObj}>
            <DotOnMapPress></DotOnMapPress>
          </CircleOnMapPress>
          <Animation></Animation>
        </FixedMarkerWrapper>

        <HeaderBar>
          <DragText>Drag map</DragText>
        </HeaderBar>
        <MapControlsWrapper>
          <GPSButton>
            <Navigation size={18} fill="black" color="white" />
          </GPSButton>
          <CustomZoomWrapper>
            <ZoomInButton>
              <Plus size={20} fill="black" color="black" />
            </ZoomInButton>
            <LineBreak></LineBreak>
            <ZoomOutButton>
              <Minus size={20} fill="black" color="black" />
            </ZoomOutButton>
          </CustomZoomWrapper>
        </MapControlsWrapper>
      </GoogleMap>
    </Wrapper>
  );
};
/////////////////////////////////////////////////////////

const Wrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 400px;
  background-color: #ebebeb;
  position: relative;

  @media ${QUERIES.tabletAndUp} {
    /* define max width */
    width: 100%;
    max-width: ${(props) => props.maxWidth + 'px'};
  }
`;

const HeaderBar = styled.div`
  position: absolute;
  max-width: 80px;
  min-width: 80px;
  height: 30px;
  display: flex;
  background-color: black;
  justify-content: center;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  /* pointer-events: none; */
`;

const DragText = styled.div`
  color: #fff;
  box-shadow: 0 3px 15px 0 rgba(40, 44, 63, 0.1);
  padding: 8px;
  font-size: 13px;
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
`;

const MapControlsWrapper = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  right: 10px;
  bottom: 20px;
  z-index: 99999999999999;
`;

const FixedMarkerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const growingCircle = keyframes`
0%{
	box-shadow:0 0 0 0 black;
	opacity:.8;
}
70%{
	box-shadow:0 0 0 18px black;
	opacity:0;
}
to{
	box-shadow:0 0 0 0 black;
	opacity:0;
}
`;

const growingCircle2 = keyframes`
0%{
	box-shadow:0 0 0 0 black;
	opacity:1;
}
70%{
	box-shadow:0 0 0 18px black;
	opacity:0;
}
to{
	box-shadow:0 0 0 0 black;
	opacity:0;
}
`;

const Animation = styled.div`
  /* opacity: 1; */
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -100%);
  transform-origin: center;
  pointer-events: none;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  will-change: transform;
  backface-visibility: hidden;

  &::before,
  ::after {
    animation: ${growingCircle} 1.4s linear infinite;
    content: '';
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 100%;
  }

  &::after {
    animation-delay: 0.4s;
    animation-name: ${growingCircle2};
    border-radius: 100%;
  }
`;

const PinSlideDown = keyframes`
  0% {
    transform: translate(-50%,-400%);
}
  100% {
    transform: translate(-50%, -100%);
  }
`;

const PinWrapper = styled.div`
  position: absolute;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(-50%, -100%);
  transform-origin: top;
  animation: ${PinSlideDown} 300ms ease-in;
  transition: all 300ms cubic-bezier(0.2, 0.8, 0.4, 1) 0s;
  pointer-events: none;
  will-change: transform;
  backface-visibility: hidden;
`;

const PinTop = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PinTopCircle = styled.div`
  background-color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;
`;

const Dot = styled.div`
  background-color: rgb(255, 255, 255);
  height: 4px;
  width: 4px;
  border-radius: 50%;
`;

const PinBottom = styled.div`
  background-color: rgb(0, 0, 0);
  width: 4px;
  height: 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;
`;

const CircleOnMapPress = styled.div`
  opacity: 0;
  visibility: hidden;
  width: 6px;
  height: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transform: translate(-50%, -100%);
  transform-origin: top;
  will-change: transform;
  transition: all 300ms cubic-bezier(0.2, 0.8, 0.4, 1) 0s;
`;

const DotOnMapPress = styled.div`
  background-color: black;
  border-radius: 2px;
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: 0px;
`;

const Button = styled.button`
  height: 34px;
  width: 100%;
  color: #686b78;
  font-weight: var(--font-weight-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  line-height: 0;
`;

const GPSButton = styled(Button)`
  width: 34px;
  background: black;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
`;

const CustomZoomWrapper = styled.div`
  display: none;

  @media ${QUERIES.tabletAndUp} {
    display: revert;
    height: 68px;
    width: 34px;
    background: #fff;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    overflow: hidden;
  }
`;

const LineBreak = styled.div`
  height: 2px;
  background-color: hsl(0deg 0% 88.63%);
`;

const ZoomInButton = styled(Button)``;

const ZoomOutButton = styled(Button)``;

export default React.memo(GoogleMapContainer);
