import React from 'react';
import styled, { keyframes } from 'styled-components';

const Shimmer = () => {
  return (
    <Wrapper>
      {Array(5)
        .fill('')
        .map((element, index) => {
          return (
            <ShimmerCard key={index}>
              <Icon></Icon>
              <CardTextWrapper>
                <MainText></MainText>
                <BottomText></BottomText>
              </CardTextWrapper>
            </ShimmerCard>
          );
        })}
    </Wrapper>
  );
};

const AnimationKeyframe = keyframes`
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
`;

const Animate = styled.div`
  animation: ${AnimationKeyframe} 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to right,
    hsla(0, 0%, 97.6%, 0.3) 25%,
    hsla(0, 0%, 94.6%, 0.3) 50%,
    hsla(0, 0%, 90.6%, 0.3) 75%
  );
  background-size: 1000px 100%;
`;

const Wrapper = styled.div`
  /* margin: 12px; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShimmerCard = styled.div`
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 12px;
`;
const Icon = styled(Animate)`
  min-width: 30px;
  min-height: 30px;
  border-radius: 500px;
  background-color: hsl(130.97, 60%, 90.3%);
  margin-left: 4px;
`;

const CardTextWrapper = styled.div`
  margin: 0 20px;
  width: 100%;
`;

const MainText = styled(Animate)`
  width: 100%;
  height: 20px;
  background-color: hsl(110.97, 60%, 94.3%);
  border-radius: 10px;
`;

const BottomText = styled(Animate)`
  margin-top: 6px;
  width: 50%;
  height: 17px;
  background-color: hsl(110.97, 60%, 97.3%);
  border-radius: 10px;
`;

export default Shimmer;
