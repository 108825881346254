import React from 'react';
import styled, { keyframes } from 'styled-components';
import Portal from '../Portal/Portal';
import { X } from 'react-feather';
import { QUERIES } from '../../constants/constants';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import ReactFocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { useEscapeKey } from '../../hooks/useEscapeKey';

const Modal = ({
  heading,
  children,
  handleDismiss,
  hideCloseButtonOnMobile,
  maxWidth = 800,
}: any) => {
  useEscapeKey(handleDismiss);

  const closeButtonStyles = {
    '--hide': hideCloseButtonOnMobile ? 'none' : 'block',
  };

  return (
    <Portal>
      <ReactFocusLock returnFocus={true}>
        <RemoveScroll>
          <ModalWrapper>
            <BackgroundOverlay
              onClick={handleDismiss}
            ></BackgroundOverlay>
            <ModalContentWrapper
              aria-modal="true"
              role="dialog"
              // tabindex="-1"
              aria-label="Search your location"
              // data-state='open'
              // style="--max-modal-width:800px;"
              maxWidth={maxWidth}
            >
              <CloseButton
                onClick={handleDismiss}
                data-test="modal-close-button"
                style={closeButtonStyles as any}
                // style="--x-color:black;"
              >
                <X size={32} stroke="currentColor" />
                <VisuallyHidden>Dismiss Modal</VisuallyHidden>
              </CloseButton>
              <ModalContent>
                <ModalContentAnimation>
                  {heading && (
                    <HeaderWrapper>
                      <Heading>{heading}</Heading>
                    </HeaderWrapper>
                  )}
                  {/* {!heading && (
                    <>
                      <SpaceWrapperMobile>
                        <Spacer y={16} />
                      </SpaceWrapperMobile>
                      <SpaceWrapperDesktop>
                        <Spacer y={32} />
                      </SpaceWrapperDesktop>
                    </>
                  )} */}
                  {/* <ChildrenContent></ChildrenContent> */}
                  {children}
                </ModalContentAnimation>
              </ModalContent>
            </ModalContentWrapper>
          </ModalWrapper>
        </RemoveScroll>
      </ReactFocusLock>
    </Portal>
  );
};

const ModalWrapper = styled.div`
  /* background: rgba(0, 0, 0, 0.33); */
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  isolation: isolate;
`;

const BlurKeyframe = keyframes`
  0% {
    opacity: 0;
    pointer-events: none;
  }

  100% {
    opacity: 1;
    pointer-events: auto;
  }
`;

const BackgroundOverlay = styled.div`
  --color-blurred-background: hsl(210deg 15% 6% / 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-blurred-background);
  /* backdrop-filter: blur(15px); */
  animation: 1000ms ease 0s 1 normal none running ${BlurKeyframe};
  overflow: hidden;
`;

const ContentKeyframe1 = keyframes`
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const ContentKeyframe2 = keyframes`
 0% {
    opacity: 0;
    pointer-events: none;
  }
100% {
    opacity: 1;
    pointer-events: auto;
  }
`;

const ContentKeyframe3 = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const ModalContentWrapper = styled.div<any>`
  @media (max-width: 550px) {
    position: relative;
    z-index: 200;
    width: 100%;
    height: 100%;
    min-width: initial;
    max-height: initial;
    padding: 0px;
    margin: 0px;
    /* animation: initial; */
    /* cubic-bezier(.4,0,.6,1); */
    /* cubic-bezier(0.07, 0.7, 0.69, 1.01) pre */
    /* animation: 300ms ease-out 250ms 1 normal both running
      ${ContentKeyframe3}; */
    /* animation: 350ms ease-out 150ms 1 normal both running
      ${ContentKeyframe3}; */
    /* 250ms ease-out 150ms 1 normal both running ${ContentKeyframe2}; */
    backface-visibility: hidden;
    will-change: transform;
    overflow: hidden;
  }

  @media ${QUERIES.tabletAndUp} {
    --max-modal-width: 800px;
    --min-modal-width: 320px;
    position: relative;
    z-index: 200;
    background: transparent;
    width: clamp(550px, 70vw, var(--max-modal-width));
    max-width: ${(props) => props.maxWidth + 'px'};
    min-width: min(var(--min-modal-width), 100vw);

    animation: 400ms cubic-bezier(0.07, 0.7, 0.69, 1.01) 250ms 1
        normal both running ${ContentKeyframe1},
      150ms ease-out 250ms 1 normal both running ${ContentKeyframe2};
    margin: 50px auto 0px;
    padding: 32px 32px 0px;
    outline: none;
    will-change: transform;
    --shadow-elevation-high: 0px 0.2px 0.3px
        hsl(var(--shadow-color) / 0.06),
      0px 2.3px 3px -0.2px hsl(var(--shadow-color) / 0.08),
      0.1px 4.3px 5.6px -0.4px hsl(var(--shadow-color) / 0.09),
      0.1px 6.6px 8.7px -0.5px hsl(var(--shadow-color) / 0.11),
      0.1px 9.8px 12.9px -0.7px hsl(var(--shadow-color) / 0.13),
      0.2px 14.3px 18.8px -0.9px hsl(var(--shadow-color) / 0.14),
      0.3px 20.8px 27.3px -1.1px hsl(var(--shadow-color) / 0.16),
      0.4px 29.6px 38.9px -1.2px hsl(var(--shadow-color) / 0.17);
    box-shadow: var(--shadow-elevation-high);
  }
`;

const CloseButton = styled.div`
  position: absolute;
  z-index: 200;
  top: 0px;
  right: 0px;
  display: var(--hide);
  padding: 24px;
  /* color: var(--x-color); */
  animation: 350ms ease 350ms 1 normal both running
    ${ContentKeyframe2};
  will-change: transform;
  cursor: pointer;

  @media ${QUERIES.tabletAndUp} {
    display: revert;
    transform: translate(-8px, calc(-100% + 32px));
    animation: 350ms ease 750ms 1 normal both running
      ${ContentKeyframe2};
    color: white;
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 2px 3px);
  }

  & > svg {
    display: block;
  }
`;

const ModalContent = styled.div`
  height: 100%;
  max-height: 100vh;
  border-radius: 0px;
  background-color: white;
  overflow-y: auto;
  /* z-index: 200; */

  @media (${QUERIES.tabletAndUp}) {
    position: relative;
    max-height: 80vh;
    border-radius: 10px;
  }
`;

const ModalContentAnimation = styled.div`
  /* animation: 300ms ease 500ms 1 normal both running
    ${ContentKeyframe2}; */
  animation: 250ms ease 200ms 1 normal both running
    ${ContentKeyframe2};
  min-height: 100%;
`;

const HeaderWrapper = styled.header`
  position: relative;
  display: block;
  padding: 16px;

  @media (${QUERIES.tabletAndUp}) {
    padding: 24px 32px;
  }
`;

const SpaceWrapperMobile = styled.div`
  display: block;

  @media (${QUERIES.tabletAndUp}) {
    display: none;
  }
`;

const SpaceWrapperDesktop = styled.div`
  display: none;

  @media (${QUERIES.tabletAndUp}) {
    display: block;
  }
`;

const Heading = styled.h2`
  font-weight: var(--font-weight-bold);
  font-size: 1.25rem;
  position: relative;
`;

const ChildrenContent = styled.div`
  padding: 0px 16px 16px;

  @media (${QUERIES.tabletAndUp}) {
    padding: 0px 32px 32px;
  }
`;

export default Modal;
