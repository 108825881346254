import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getToken = () => {
  const jwtToken = cookies.get('token');
  if (!jwtToken) return null;

  return jwtToken;
};

const isTokenExpired = (token: any) => {
  if (!token) return true;
  const decodedJWT: any = jwt_decode(token);

  if (Math.floor(Date.now() / 1000) > decodedJWT.exp) return true;
  return false;
};

export { getToken, isTokenExpired };
