import { createSlice } from '@reduxjs/toolkit';
import { crudOfItem } from './cartThunk';

export interface IState {
  data: {
    items: any;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const cartData = JSON.parse(localStorage.getItem('cartData') as any);

const initialState: IState = {
  data: {
    items: cartData?.cartItems || {},
  },
  status: 'idle',
  error: null,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // reset: (state) => {
    //   state.status = 'idle';
    //   state.error = null;
    //   state.data = null;
    // },

    setCartItems: (state, action) => {
      state.data.items = action.payload;
    },
    removeItemFromReduxCart: (state, action) => {
      console.log(
        '🚀 ~ file: cartSlice.ts:30 ~ addItemToCart ~ action:',
        action.payload.cartItem.itemId
      );

      const item = action.payload;

      delete state.data.items[action.payload.cartItem.itemId];

      console.log(state.data.items);

      // const newItemObj = {} as any;
      // newItemObj[item.itemId] = item;

      // state.data.items = newItemObj;
      // const newItem = action.payload;
      // const existingItem = state.items.find(
      //   (item) => item.id === newItem.id
      // );
      // state.totalQuantity++;
      // state.changed = true;
      // if (!existingItem) {
      //   state.items.push({
      //     id: newItem.id,
      //     price: newItem.price,
      //     quantity: 1,
      //     totalPrice: newItem.price,
      //     name: newItem.title,
      //   });
      // } else {
      //   existingItem.quantity++;
      //   existingItem.totalPrice =
      //     existingItem.totalPrice + newItem.price;
      // }
    },
    handleAddItemToCart: (state, action) => {
      console.log(
        '🚀 ~ file: cartSlice.ts:30 ~ addItemToCart ~ action:',
        action.payload
      );

      const newItem = action.payload;
      const newItemObj = {} as any;
      newItemObj[newItem.itemId] = newItem;

      state.data.items = newItemObj;
      // const newItem = action.payload;
      // const existingItem = state.items.find(
      //   (item) => item.id === newItem.id
      // );
      // state.totalQuantity++;
      // state.changed = true;
      // if (!existingItem) {
      //   state.items.push({
      //     id: newItem.id,
      //     price: newItem.price,
      //     quantity: 1,
      //     totalPrice: newItem.price,
      //     name: newItem.title,
      //   });
      // } else {
      //   existingItem.quantity++;
      //   existingItem.totalPrice =
      //     existingItem.totalPrice + newItem.price;
      // }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(crudOfItem.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(crudOfItem.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.items = action.payload?.data.cartItems;
      })
      .addCase(crudOfItem.rejected, (state, action: any) => {
        state.status = 'failed';
        // get cartData cartItems from localStorage then set previous items in items state
        // state.data.items = null;
        state.error = action.payload;
      });
  },
});

export const {
  handleAddItemToCart,
  removeItemFromReduxCart,
  setCartItems,
} = cartSlice.actions;
export default cartSlice.reducer;

// import { createSlice } from '@reduxjs/toolkit';

// const cartSlice = createSlice({
//   name: 'cart',
//   initialState: {
//     items: [],
//     totalQuantity: 0,
//     changed: false,
//   },
//   reducers: {
//     replaceCart(state, action) {
//       state.totalQuantity = action.payload.totalQuantity;
//       state.items = action.payload.items;
//     },
//     addItemToCart(state, action) {
//       const newItem = action.payload;
//       const existingItem = state.items.find((item) => item.id === newItem.id);
//       state.totalQuantity++;
//       state.changed = true;
//       if (!existingItem) {
//         state.items.push({
//           id: newItem.id,
//           price: newItem.price,
//           quantity: 1,
//           totalPrice: newItem.price,
//           name: newItem.title,
//         });
//       } else {
//         existingItem.quantity++;
//         existingItem.totalPrice = existingItem.totalPrice + newItem.price;
//       }
//     },
//     removeItemFromCart(state, action) {
//       const id = action.payload;
//       const existingItem = state.items.find((item) => item.id === id);
//       state.totalQuantity--;
//       state.changed = true;
//       if (existingItem.quantity === 1) {
//         state.items = state.items.filter((item) => item.id !== id);
//       } else {
//         existingItem.quantity--;
//         existingItem.totalPrice = existingItem.totalPrice - existingItem.price;
//       }
//     },
//   },
// });

// export const cartActions = cartSlice.actions;

// export default cartSlice;
