import { createSlice } from '@reduxjs/toolkit';
import { reverseGeocode } from './SearchLocationThunk';

interface IState {
  data: {
    userLocation: any;
    reverseGeocodeApiData: any;
  };
  isSearchLocationModalOpen: boolean;
  proceedToConfirmAddress: boolean;
  reverseApiStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  isGeoLocationLoading: boolean;
  message: string | null;
  locationError: null;
  isLocationPopoverOpen: boolean;
}

const initialState: IState = {
  data: {
    userLocation: null,
    reverseGeocodeApiData: null,
  },
  isSearchLocationModalOpen: false,
  proceedToConfirmAddress: false,
  reverseApiStatus: 'idle',
  isGeoLocationLoading: false,
  message: null,
  locationError: null,
  isLocationPopoverOpen: false,
};

const SearchLocationSlice = createSlice({
  name: 'SearchLocationSlice',
  initialState: initialState,
  reducers: {
    toggleSearchLocationModal: (state, action) => {
      state.isSearchLocationModalOpen = action.payload;
    },
    cacheSearchResults: (state, action) => {
      state = Object.assign(state, action.payload);
    },
    setReverseGeocodeApiData: (state, action) => {
      state.data.reverseGeocodeApiData = action.payload;
    },
    setGeoLocationLoadingStatus: (state, action) => {
      state.isGeoLocationLoading = action.payload;
    },
    setUserLocation: (state, action) => {
      state.data.userLocation = action.payload;
    },
    setProceedToConfirmAddress: (state, action) => {
      state.proceedToConfirmAddress = action.payload;
    },
    setLocationError: (state, action) => {
      state.locationError = action.payload;
    },
    showLocationPopover: (state, action) => {
      state.isLocationPopoverOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(reverseGeocode.pending, (state) => {
        state.reverseApiStatus = 'loading';
      })
      .addCase(reverseGeocode.fulfilled, (state, action) => {
        state.reverseApiStatus = 'succeeded';
        state.data.reverseGeocodeApiData = action.payload;
      })
      .addCase(reverseGeocode.rejected, (state, action: any) => {
        state.reverseApiStatus = 'failed';
        state.data.reverseGeocodeApiData = null;
        state.message = action.payload;
      });
  },
});

export const {
  toggleSearchLocationModal,
  cacheSearchResults,
  setUserLocation,
  setLocationError,
  setReverseGeocodeApiData,
  setGeoLocationLoadingStatus,
  setProceedToConfirmAddress,
  showLocationPopover,
} = SearchLocationSlice.actions;

export default SearchLocationSlice.reducer;
