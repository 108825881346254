import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Restaurant from '../Restaurant/Restaurant';
import HomeCarousel from '../Carousel/HomeCarousel';
import WelcomeModal from '../WelcomeModal/WelcomeModal';
import { Accordion } from '../Accordion';
// import '../SignupAnimation/confettiAnimation';

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      {/* <button onClick={() => setIsOpen(true)}>Open animation</button> */}
      {/* {isOpen && <WelcomeModal setIsOpen={setIsOpen} />} */}
      {/* <WelcomeModal setIsOpen={setIsOpen} /> */}
      <WelcomeModal />
      {/* <HomeCarousel />
      <Restaurant /> */}
      {/* <div
        style={{ maxWidth: '800px', width: '100%', margin: '0 auto' }}
      >
        <Accordion
          title="Is team pricing available"
          defaultExpanded={true}
        >
          Yes! You can purchase a license that you can share with your
          entire team.
        </Accordion>
        <Accordion
          title="Is team pricing available"
          defaultExpanded={true}
        >
          Yes! You can purchase a license that you can share with your
          entire team.
        </Accordion>
      </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #f0fbff; */
`;

export default HomePage;
