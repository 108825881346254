import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  cacheSearchResults,
  setUserLocation,
  toggleSearchLocationModal,
} from './SearchLocationSlice';
import userLocationUtil from '../../utils/userLocationUtil';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const reverseGeocode = createAsyncThunk(
  'reverseGeocodeThunk',
  async (coordinates: any, thunkAPI: any) => {
    try {
      // const map_api_key = 'AIzaSyDPYYANdDK_TJg9bTk9bop0hJQNuVO4erI';
      const map_api_key = 'AIzaSyDPYYANdDK_TJg9bTk9bop0hJQNuVO4cfI';
      const { lat, lng } = coordinates;

      const { data } = (await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${map_api_key}`
      )) as any;

      const payload = data.results[0];

      const formattedAddress = await userLocationUtil(payload);

      localStorage.setItem(
        'ymiieat_userLocation',
        JSON.stringify(formattedAddress)
      );

      cookies.set('userLocation', formattedAddress, {
        path: '/',
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      });

      thunkAPI.dispatch(setUserLocation(formattedAddress));
      thunkAPI.dispatch(toggleSearchLocationModal(false));

      return formattedAddress;
    } catch (error: any) {
      console.log(error);

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(
        'Error while reverse geocoding.'
      );
    }
  }
);
