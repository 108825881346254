import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { QUERIES } from '../../constants/constants';
// import LogoImage from '../../assets/FACE LOGO 2 - Copy.png';
// import LogoImage from '../../assets/WHITE LOGO.png';
import LogoImage from '../../assets/logo.svg';

const Logo = (props: any) => {
  return (
    <AnchorLink to="/">
      {/* YmiiEat */}
      <Wrapper {...props}>
        {/* <img src={LogoImage} alt="" height={38} width={130} /> */}
        <Image src={LogoImage} alt="" />
        {/* YmiiEat */}
      </Wrapper>
    </AnchorLink>
  );
};

const AnchorLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: var(--color-primary);
  /* height: 44px; */
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-right: 16px;
  padding: 14px 0;

  @media ${QUERIES.tabletAndUp} {
    margin-right: 32px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;

  @media ${QUERIES.laptopAndUp} {
    font-size: 24px;
  }
`;

const Image = styled.img`
  height: 32px;
  @media ${QUERIES.laptopAndUp} {
    height: 36px;
  }
`;

export default Logo;
