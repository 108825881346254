import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import React from 'react';
import App from './App';
import About from './components/About/About';
import AuthLayout from './components/AuthLayout/AuthLayout';
import Login from './components/AuthLayout/LoginUsingEmail';
import SignUp from './components/AuthLayout/SignUp';
import Error from './components/Error/Error';
import Address from './feature/address/Address';
import Chat from './components/Socket/Chat';
import Checkout from './components/Checkout/Checkout';
import Cart from './components/Cart/Cart';
import OrdersPage from './feature/order/OrdersPage';
import Restaurant from './components/Restaurant/Restaurant';
import HomePage from './components/HomePage/HomePage';
import LoginUsingPhoneNumber from './components/AuthLayout/LoginUsingPhoneNumber';
import LoginUsingEmail from './components/AuthLayout/LoginUsingEmail';
import Denied from './components/Denied/Denied';
import Notfound from './components/Notfound/Notfound';
import RequireAuth from './components/RequireAuth/RequireAuth';
import WelcomeModal from './components/WelcomeModal/WelcomeModal';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<AuthLayout />} errorElement={<Error />}>
        <Route path="/login" element={<LoginUsingPhoneNumber />} />
        <Route path="/signup" element={<SignUp />} />
      </Route>

      {/* https://www.swiggy.com/restaurants/mcdonalds-sadar-ranjit-avenue-amritsar-605973 */}
      <Route path="/" element={<App />} errorElement={<Error />}>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/about" element={<About />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/address" element={<Address />} />
        <Route path="/welcome" element={<WelcomeModal />} /> */}

        {/* ========================================= */}
        {/* for mobile backward compatibility */}
        {/* to resolve an issue to close modal when mobile button is pressed */}
        {/*// imp: later change to "restaurants/:slug"  */}
        {/* <Route path="/restaurants" element={<Restaurant />} /> */}

        {/* <Route path="/orders" element={<OrdersPage />} /> */}
        {/* Just for practice */}
        {/* <Route path="/chat/:roomid" element={<Chat />} /> */}
      </Route>

      {/* <Route
        element={<RequireAuth allowedRoles={['ADMIN', 'CUSTOMER']} />}
      >
        <Route path="/checkout" element={<Checkout />} />
      </Route> */}

      {/* <Route
        element={<RequireAuth allowedRoles={['ADMIN']} />}
      ></Route> */}

      {/* <Route path="/denied" element={<Denied />} />
      <Route path="*" element={<Notfound />} /> */}
      <Route path="*" element={<App />} />
    </Route>
  )
);

export default Router;

{
  /* <Route path="/user/profile" element={<Profile />} />
        <Route path="/user/editprofile" element={<EditProfile />} />

        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="/checkout/success"
          element={<CheckoutSuccess />}
        />
        <Route path="/checkout/fail" element={<CheckoutFailure />} />

        <Route
          path="/course/displaylectures"
          element={<DisplayLectures />}
        /> */
}

{
  /* <Route path="/course/create" element={<CreateCourse />} />
        <Route path="/course/addlecture" element={<AddLecture />} /> */
}
