import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import SearchBar from './components/SearchBar';
import GeoLocation from './components/GeoLocation';
import SearchResult from './components/SearchResult';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import {
  cacheSearchResults,
  setProceedToConfirmAddress,
  setUserLocation,
  toggleSearchLocationModal,
} from './SearchLocationSlice';
import axios from 'axios';
import Spacer from '../../components/Spacer/Spacer';
import Map from '../address/components/AddressMapIndex';
import { ArrowLeft } from 'react-feather';
import TextInput from '../../components/TextInput/TextInput';
import { QUERIES } from '../../constants/constants';
import BottomModal from '../../components/Modal/BottomModal';
import MobileBottomNav from '../../components/MobileBottomNav/MobileBottomNav';
import Footer from '../../components/Footer/Footer';
import AddressForm from '../../components/AddressForm/AddressForm';
import userLocationUtil from '../../utils/userLocationUtil';
import { placeAutocompleteApi } from '../../services';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

const SearchLocation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const { data: reverseGeocodeData, reverseApiStatus } =
    useAppSelector((state) => state.searchLocation);
  const reverseGeocodeApiData =
    reverseGeocodeData.reverseGeocodeApiData;

  const searchCache: any = useAppSelector(
    (state) => state.searchLocation
  );
  const { proceedToConfirmAddress, isGeoLocationLoading } =
    useAppSelector((state) => state.searchLocation);

  const getSearchSuggestions = useCallback(async () => {
    const data = await placeAutocompleteApi(searchTerm);

    // TODO: If there is no data then set an error
    if (data) {
      setSuggestions(data);

      dispatch(
        cacheSearchResults({
          [searchTerm]: data,
        })
      );
      setIsLoading(false);
    } else {
      // TODO: set an error
    }
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (searchTerm.length === 0) {
      setSuggestions([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const timer = setTimeout(() => {
      if (searchCache[searchTerm]) {
        setSuggestions(searchCache[searchTerm]);
        setIsLoading(false);
      } else {
        if (searchTerm.length <= 2) return;
        getSearchSuggestions();
      }
    }, 200);

    return () => clearTimeout(timer);
  }, [dispatch, searchTerm, searchCache, getSearchSuggestions]);

  const handleClearSearchSuggestions = () => {
    setSuggestions([]);
  };

  const handleSkipAndAddAddressLater = async () => {
    cookies.set('userLocation', reverseGeocodeApiData, {
      path: '/',
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
    });

    localStorage.setItem(
      'ymiieat_userLocation',
      JSON.stringify(reverseGeocodeApiData)
    );

    dispatch(setUserLocation(reverseGeocodeApiData));
    dispatch(setProceedToConfirmAddress(false));
    dispatch(toggleSearchLocationModal(false));
    history.replaceState(null, '', '/');
  };

  return (
    <Wrapper>
      {/* if not logged in then show this one   */}
      {!proceedToConfirmAddress ? (
        <>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <GeoLocation />
          <Spacer y={10} />
          <SearchResult
            searchTerm={searchTerm}
            clearSearchSuggestions={handleClearSearchSuggestions}
            isLoading={isLoading}
            suggestions={suggestions}
          />
        </>
      ) : (
        <SetDeliveryLocationWrapper>
          <Header>
            <BackButton
              onClick={() => {
                dispatch(setProceedToConfirmAddress(false));
                setSearchTerm('');
              }}
            >
              <ArrowLeft size={20} />
            </BackButton>
            <Heading>Set delivery location</Heading>
          </Header>
          <MapWrapper>
            <Map maxWidth={900} />
          </MapWrapper>

          <TextInput
            label="Address"
            type="text"
            key={reverseGeocodeApiData?.address}
            defaultValue={reverseGeocodeApiData?.address}
            readOnly
            disabled
            maxLength={100}
          />

          {!isMobileModalOpen && (
            <MobileButtonsWrapper>
              <AddAddress
                disabled={!reverseGeocodeApiData?.address}
                onClick={() => setIsMobileModalOpen(true)}
              >
                ADD ADDRESS
              </AddAddress>

              <SkipAndAddLater
                disabled={!reverseGeocodeApiData?.address}
                onClick={handleSkipAndAddAddressLater}
              >
                SKIP & ADD LATER
              </SkipAndAddLater>
            </MobileButtonsWrapper>
          )}

          {!isFormVisible && (
            <DesktopButtonsWrapper>
              <AddAddress
                disabled={!reverseGeocodeApiData?.address}
                onClick={() => setIsFormVisible(true)}
              >
                ADD ADDRESS
              </AddAddress>

              <SkipAndAddLater
                disabled={!reverseGeocodeApiData?.address}
                onClick={handleSkipAndAddAddressLater}
              >
                SKIP & ADD LATER
              </SkipAndAddLater>
            </DesktopButtonsWrapper>
          )}

          {isFormVisible && <AddressForm />}

          {isMobileModalOpen && (
            <BottomModal
              heading="Set delivery address"
              handleDismiss={() => setIsMobileModalOpen(false)}
              height={100}
            >
              {/* <Heading>Set delivery address</Heading> */}
              <AddressInput>
                <TextInput
                  label="Address"
                  type="text"
                  key={reverseGeocodeApiData?.address}
                  defaultValue={reverseGeocodeApiData?.address}
                  readOnly
                  disabled
                  maxLength={100}
                />
              </AddressInput>

              <AddressForm />
              {/* <Spacer y={50} /> */}
            </BottomModal>
          )}
        </SetDeliveryLocationWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* TODO: update this height according to */
  min-height: 426px;
  width: 100%;
  position: relative;
`;

const SetDeliveryLocationWrapper = styled.div`
  padding: 0 16px;
  margin: 0 -16px;

  @media ${QUERIES.tabletAndUp} {
    padding: 0 32px;
    margin: 0 -32px;
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 999999;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 10px -8px;
  margin: 0 -16px;
  margin-top: -16px;
  padding: 0 16px;
  /* border-bottom: 2px solid black; */

  @media ${QUERIES.tabletAndUp} {
    margin: 0 -32px;
    margin-top: -32px;
    padding: 0 32px;
  }
`;

const BackButton = styled.button`
  padding: 12px;
  margin-left: -10px;
  width: 60px;
  height: 100%;

  & > svg {
    display: block;
  }
`;

const Heading = styled.div`
  font-size: 16px;
  font-weight: var(--font-weight-bold);
`;

const MapWrapper = styled.div`
  /* padding-top: 4px; //does it affect map center */
  height: 100%;
  height: 400px;
  margin: 4px -16px 0;

  @media ${QUERIES.tabletAndUp} {
    margin: 2px -32px 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
  width: 100%;

  @media ${QUERIES.tabletAndUp} {
    flex-direction: row;
  }
`;

const MobileButtonsWrapper = styled(ButtonsWrapper)`
  @media ${QUERIES.tabletAndUp} {
    display: none;
  }
`;
const DesktopButtonsWrapper = styled(ButtonsWrapper)`
  display: none;

  @media ${QUERIES.tabletAndUp} {
    display: flex;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
  width: 100%;
  font-size: 15px;
  border-radius: 10px;
  font-weight: var(--font-weight-semi-bold);

  @media ${QUERIES.tabletAndUp} {
    width: 50%;
  }
`;

const AddAddress = styled(Button)`
  color: black;
  border: 1px solid green;
`;

const SkipAndAddLater = styled(Button)`
  background-color: black;
  font-weight: var(--font-weight-medium);
`;

const AddressInput = styled.div`
  margin-top: -16px;
`;

export default React.memo(SearchLocation);
