import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Portal from '../Portal';
import styled, { keyframes } from 'styled-components';
import TopLeft from '../../assets/signup/signup-left-top.png';
import TopRight from '../../assets/signup/signup-top-right.png';
import BottomLeft from '../../assets/signup/signup-bottom-left.png';
import BottomRight from '../../assets/signup/signup-right-bottom.png';
import { QUERIES } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hook';
import Logo from '../../assets/logo.svg';

const WelcomeModal = ({ setIsOpen }: any) => {
  const navigate = useNavigate();
  const posRef = useRef<any>({});
  const { userInfo } = useAppSelector((state) => state.auth.data);
  const name = userInfo?.name?.split(' ')[0];

  const confettiCount = 160;
  const sequinCount = 100;

  // "physics" variables
  const gravityConfetti = 0.3;
  const gravitySequins = 0.44;
  const dragConfetti = 0.075;
  const dragSequins = 0.02;
  const terminalVelocity = 3;

  const canvasElement = document.createElement('canvas');
  canvasElement.id = 'canvas';
  document.body.appendChild(canvasElement);
  const canvas: any = document.getElementById('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  // add Confetto/Sequin objects to arrays to draw them
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const confetti = [] as any;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sequins = [] as any;

  // colors, back side is darker for confetti flipping
  const colors = [
    { front: '#a50000', back: '#ee8282' }, // Purple
    { front: '#5f0000', back: '#ff0000' }, // Purple
    { front: '#f7b500', back: '#e9cd80' }, // Light Blue
  ];

  // helper function to pick a random number within a range
  const randomRange = (min: any, max: any) =>
    Math.random() * (max - min) + min;

  // helper function to get initial velocities for confetti
  // this weighted spread helps the confetti look more realistic
  const initConfettoVelocity = (xRange: any, yRange: any) => {
    const x = randomRange(xRange[0], xRange[1]);
    const range = yRange[1] - yRange[0] + 1;
    let y =
      yRange[1] -
      Math.abs(randomRange(0, range) + randomRange(0, range) - range);
    if (y >= yRange[1] - 1) {
      // Occasional confetto goes higher than the max
      y += Math.random() < 0.25 ? randomRange(1, 3) : 0;
    }
    return { x: x, y: -y };
  };

  // Confetto Class
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function Confetto(this: any) {
    this.randomModifier = randomRange(0, 99);
    this.color = colors[Math.floor(randomRange(0, colors.length))];
    this.dimensions = {
      x: randomRange(5, 9),
      y: randomRange(8, 15),
    };
    this.position = {
      x: randomRange(
        canvas.width / 2 - posRef?.current?.left / 4,
        canvas.width / 2 + posRef?.current?.left / 4
      ),
      y: posRef?.current?.top + 40,
    };
    this.rotation = randomRange(0, 2 * Math.PI);
    this.scale = {
      x: 1,
      y: 1,
    };
    this.velocity = initConfettoVelocity([-9, 9], [6, 11]);
  }

  Confetto.prototype.update = function () {
    // apply forces to velocity
    this.velocity.x -= this.velocity.x * dragConfetti;
    this.velocity.y = Math.min(
      this.velocity.y + gravityConfetti,
      terminalVelocity
    );
    this.velocity.x +=
      Math.random() > 0.5 ? Math.random() : -Math.random();

    // set position
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;

    // spin confetto by scaling y and set the color, .09 just slows cosine frequency
    this.scale.y = Math.cos(
      (this.position.y + this.randomModifier) * 0.09
    );
  };

  // Sequin Class
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function Sequin(this: any) {
    (this.color =
      colors[Math.floor(randomRange(0, colors.length))].back),
      (this.radius = randomRange(1, 2)),
      (this.position = {
        x: randomRange(
          canvas.width / 2 - posRef?.current?.left / 3,
          canvas.width / 2 + posRef?.current?.left / 3
        ),
        y: posRef?.current?.top,
      }),
      (this.velocity = {
        x: randomRange(-6, 6),
        y: randomRange(-8, -12),
      });
  }
  Sequin.prototype.update = function () {
    // apply forces to velocity
    this.velocity.x -= this.velocity.x * dragSequins;
    this.velocity.y = this.velocity.y + gravitySequins;

    // set position
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;
  };

  // add elements to arrays to be drawn
  const initBurst = useCallback(() => {
    for (let i = 0; i < confettiCount; i++) {
      confetti.push(new (Confetto as any)());
    }
    for (let i = 0; i < sequinCount; i++) {
      sequins.push(new (Sequin as any)());
    }
  }, [Confetto, Sequin, confetti, sequins]);

  // draws the elements on the canvas
  const render = useCallback(() => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    confetti.forEach((confetto: any, index: any) => {
      const width = confetto.dimensions.x * confetto.scale.x;
      const height = confetto.dimensions.y * confetto.scale.y;

      // move canvas to position and rotate
      ctx.translate(confetto.position.x, confetto.position.y);
      ctx.rotate(confetto.rotation);

      // update confetto "physics" values
      confetto.update();

      // get front or back fill color
      ctx.fillStyle =
        confetto.scale.y > 0
          ? confetto.color.front
          : confetto.color.back;

      // draw confetto
      ctx.fillRect(-width / 2, -height / 2, width, height);

      // reset transform matrix
      ctx.setTransform(1, 0, 0, 1, 0, 0);

      // clear rectangle where button cuts off
      if (confetto.velocity.y < 0) {
        ctx.clearRect(
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2,
          canvas.height / 2
          // button?.offsetWidth,
          // button?.offsetHeight
        );
      }
    });

    sequins.forEach((sequin: any, index: any) => {
      // move canvas to position
      ctx.translate(sequin.position.x, sequin.position.y);

      // update sequin "physics" values
      sequin.update();

      // set the color
      ctx.fillStyle = sequin.color;

      // draw sequin
      ctx.beginPath();
      ctx.arc(0, 0, sequin.radius, 0, 2 * Math.PI);
      ctx.fill();

      // reset transform matrix
      ctx.setTransform(1, 0, 0, 1, 0, 0);

      // clear rectangle where button cuts off
      if (sequin.velocity.y < 0) {
        ctx.clearRect(
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2,
          canvas.height / 2

          // canvas.width / 2 - button?.offsetWidth / 2,
          // canvas.height / 2 + button?.offsetHeight / 2,
          // button?.offsetWidth,
          // button?.offsetHeight
        );
      }
    });

    // remove confetti and sequins that fall off the screen
    // must be done in seperate loops to avoid noticeable flickering
    confetti.forEach((confetto: any, index: any) => {
      if (confetto.position.y >= canvas.height)
        confetti.splice(index, 1);
    });
    sequins.forEach((sequin: any, index: any) => {
      if (sequin.position.y >= canvas.height)
        sequins.splice(index, 1);
    });

    window.requestAnimationFrame(render);
  }, [
    // button?.offsetHeight,
    // button?.offsetWidth,
    canvas.height,
    canvas.width,
    confetti,
    ctx,
    sequins,
  ]);

  // cycle through button states when clicked
  const clickButton = () => {
    console.log('burst clicked');
    initBurst();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const addLinks = () => {
      // Prepare the CSS Link
      const myLink = document.createElement('link');
      myLink.rel = 'preconnect';
      myLink.href = 'https://fonts.googleapis.com';

      const myLink2 = document.createElement('link');
      myLink2.rel = 'preconnect';
      myLink2.href = 'https://fonts.gstatic.com';
      myLink2.crossOrigin = 'crossorigin';

      const myLink3 = document.createElement('link');
      myLink2.rel = 'stylesheet';
      myLink2.href =
        'https://fonts.googleapis.com/css2?family=Caveat&display=swap';

      // Inject the CSS Link
      document.head.appendChild(myLink);
      document.head.appendChild(myLink2);
      document.head.appendChild(myLink3);
    };

    addLinks();
  }, []);

  // kick off the render loop
  useEffect(() => {
    const timer = setTimeout(() => {
      initBurst();
      render();
    }, 3500);
    return () => clearTimeout(timer);
  }, [initBurst, render]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const div = document.querySelector(Name);
      const rect: any = div?.getBoundingClientRect();
      const offset = {
        left: rect?.left + window.scrollX,
        top: rect?.top + window.scrollY,
      };
      posRef.current = offset as any;
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Portal>
      <Wrapper>
        <WrapperContent>
          <TopLeftImageWrapper>
            <Image src={TopLeft} alt="signup design element" />
          </TopLeftImageWrapper>
          <TopRightImageWrapper>
            <Image src={TopRight} alt="signup design element" />
          </TopRightImageWrapper>
          <BottomLeftImageWrapper>
            <Image src={BottomLeft} alt="signup design element" />
          </BottomLeftImageWrapper>
          <BottomRightImageWrapper>
            <Image src={BottomRight} alt="signup design element" />
          </BottomRightImageWrapper>
          <Header>
            <WelcomeText>Welcome</WelcomeText>

            <ToText>to</ToText>
            <Name>
              {/* <YmiiEatText>YmiiEat</YmiiEatText>, {name}! */}
              <YmiiEatText>YmiiEat</YmiiEatText>!
            </Name>
          </Header>

          <ImgaeWrapper>
            <Image src={Logo} alt="" width={160} height={100} />
          </ImgaeWrapper>

          {/* <GrandOpeningText>Grand Opening</GrandOpeningText>
          <InvitationText>
            You are invited to join us for the grand opening of
            YmiiEat
          </InvitationText>

          <DateText>on 26 December, at 3pm.</DateText> */}

          {/* <Button
            onClick={() => {
              clickButton();
              setIsOpen(false);
              navigate('/');
            }}
          >
            Continue
          </Button> */}
        </WrapperContent>
      </Wrapper>
    </Portal>
  );
};

const keyFrameRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
    /* clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%); */
  }



  100% {
    transform: translateX(0%);
      /* clip-path: polygon(0% 0%, 105% 0%, 105% 100%, 0% 100%); */
  }
`;

const opacityKeyframe = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: .75;
  }
  100% {
    opacity: 1;
  }
`;

const keyFrameLeftToRight = keyframes`
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  }

  100% {
      clip-path: polygon(0% 0%, 105% 0%, 105% 100%, 0% 100%);
  }
`;

const Wrapper = styled.div<any>`
  /* z-index: 99999999999; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  width: 100%;
  padding-top: 100px;
  opacity: 1;
  animation: 350ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 300ms 1 normal
    both running ${keyFrameRightToLeft};
`;

const WrapperContent = styled.div`
  animation: 450ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 700ms 1 normal
    both running ${opacityKeyframe};
`;

const Header = styled.div`
  /* width: 100%; */

  @media ${QUERIES.tabletAndUp} {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin: 0 auto;
  }
`;

const Text = styled.div`
  margin: 0px auto;
  font-size: 40px;
  font-family: 'Caveat', cursive;
  font-weight: 900;
  color: #a50000;
  text-align: center;

  @media ${QUERIES.tabletAndUp} {
    font-size: 48px;
  }
`;

const WelcomeText = styled(Text)`
  /* width: fit-content; */
  /* font-size: 62px; */
  /* transform: translateX(-20px) rotate(0deg); */
  animation: 1000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 600ms 1
    normal both running ${keyFrameLeftToRight};

  @media (max-width: 550px) {
    font-size: 40px;
  }
`;
const ToText = styled(Text)`
  /* transform: translateX(-20px) rotate(0deg); */
  animation: 400ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 1400ms 1
    normal both running ${keyFrameLeftToRight};
`;

const YmiiEatText = styled.div`
  display: inline-block;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #f7b500, #a50000);
  color: transparent;
  padding-right: 6px;
`;

const Name = styled(Text)`
  animation: 1200ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 1600ms 1
    normal both running ${keyFrameLeftToRight};
`;

const ImgaeWrapper = styled(Text)`
  padding-top: 80px;
  display: flex;
  justify-content: center;
  z-index: 999999;
  animation: 2000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 2800ms 1
    normal both running ${keyFrameLeftToRight};
`;

const GrandOpeningText = styled(Text)`
  margin-top: 60px;
  /* font-size: 16px; */
  width: 100%;
  max-width: 290px;
  animation: 2000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 2800ms 1
    normal both running ${keyFrameLeftToRight};
`;

const InvitationText = styled(Text)`
  margin-top: 10px;
  font-size: 20px;
  width: 100%;
  max-width: 290px;
  animation: 2000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 2800ms 1
    normal both running ${keyFrameLeftToRight};

  @media ${QUERIES.tabletAndUp} {
    font-size: 24px;
    max-width: 100%;
  }
`;

const DateText = styled(Text)`
  padding: 10px;
  font-size: 26px;
  width: 100%;
  max-width: 200px;
  animation: 2000ms cubic-bezier(0.33, 0.21, 0.26, 0.93) 2800ms 1
    normal both running ${keyFrameLeftToRight};
  z-index: 999999999999;
  /* display: inline-block; */
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #f7b500, #a50000);
  color: transparent;

  @media ${QUERIES.tabletAndUp} {
    max-width: 100%;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 150px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: var(--color-primary);
  font-weight: 600;
  outline: none;
  border-radius: 12px;
  padding: 10px 24px;
  cursor: pointer;
  background-color: hsl(0deg 100% 98.35%);
  --shadow-color: 0deg 100% 38.35%;
  box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
    0px 0px 4px -2px hsl(var(--shadow-color));
  animation: 1500ms ease 2500ms 1 normal both running
    ${opacityKeyframe};

  &:hover {
    background-color: hsl(0deg 100% 97%);
  }

  @media (max-width: 550px) {
    bottom: 30%;
  }

  @media ${QUERIES.tabletAndUp} {
    /* bottom: 60%; */
    margin-top: 40px;
  }
`;

const TopLeftImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 30%;
`;

const TopRightImageWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 85%;
  right: 0;
  margin-left: auto;
`;

const BottomLeftImageWrapper = styled.div`
  position: absolute;
  height: 20vh;
  bottom: 0;
  left: 0;
  transform: translate(-5%, 10%) rotate(10deg);
`;
const BottomRightImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  top: 30%;
`;

const Image = styled.img`
  display: block;
  height: 100%;
  max-width: 100%;
  z-index: 999999;
`;

export default WelcomeModal;
