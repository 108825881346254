import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  handleAddItemToCart,
  removeItemFromReduxCart,
} from './cartSlice';
import Cookies from 'universal-cookie';
import { getToken } from '../../utils/tokenUtils';

const cookies = new Cookies();

const CART_SERVICE = 'localhost:9000';

export const crudOfItem = createAsyncThunk(
  'cart/crudOfItem',
  async (data: any, thunkAPI: any) => {
    console.log('--------------runnign');

    const { cartItem, userId, restaurantId, addressId } = data;
    console.log(
      '🚀 ~ file: cartThunk.ts:14 ~ thunkAPI:',
      thunkAPI.getState().cart
    );

    try {
      if (cartItem.quantity == 0) {
        thunkAPI.dispatch(
          removeItemFromReduxCart({
            cartItem,
            userId,
            restaurantId,
            addressId,
          })
        );
      }

      // get latest cart items
      const cart = thunkAPI.getState().cart;
      const items = cart?.data?.items;

      const copyOfItemsObj = { ...items };

      // else check item already present then replace with new payload
      if (copyOfItemsObj[cartItem.itemId]) {
        console.log(items[cartItem.itemId], 'is exists');
        copyOfItemsObj[cartItem.itemId] = cartItem;
      }

      const cartItems = Object.values(copyOfItemsObj);

      // add if item not present in the cart
      if (cartItem.quantity > 0 && !copyOfItemsObj[cartItem.itemId]) {
        console.log('djklfjlskdjf', copyOfItemsObj[cartItem.itemId]);
        cartItems.push(cartItem);
      }

      const token = getToken();
      const location = cookies.get('userLocation');

      const payload = {
        cartItems,
        userId,
        restaurantId,
        addressId,
      };

      console.log('🚀 ~ file: cartThunk.ts:34 ~ payload:', payload);

      const { data } = await axios.post(
        `https://ymiieat.in/api/v1/cart`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            Cookies: JSON.stringify(location),
          },
        }
      );
      // const { data } = await axios.post(
      //   `http://${CART_SERVICE}/api/v1/cart`,
      //   payload,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${token}`,
      //       Cookies: JSON.stringify(location),
      //     },
      //   }
      // );
      console.log('🚀 ~ file: cartThunk.ts:42 ~ data:', data);

      if (data.success) {
        // set cartItems to cartData in localstorage
        const cartData = JSON.parse(
          localStorage.getItem('cartData') as any
        );
        cartData.cartItems = data.data.cartItems;

        localStorage.setItem('cartData', JSON.stringify(cartData));
      }

      return data;
    } catch (error: any) {
      console.log(error);

      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const getCart = createAsyncThunk(
//   'cart/getCart',
//   async (_, thunkAPI: any) => {
//     try {
//       const { userInfo } = thunkAPI.getState().auth;
//       const token = userInfo.authtoken;

//       return await cartService.getCart(token);
//     } catch (error: any) {
//       console.log(error);

//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// export const cartCheckout = createAsyncThunk(
//   'cart/cartCheckout',
//   async (_, thunkAPI: any) => {
//     try {
//       const { userInfo } = thunkAPI.getState().auth;
//       const token = userInfo.authtoken;
//       console.log('🚀 ~ file: CartThunk.ts:81 ~ token', token);

//       return await cartService.cartCheckout(token);
//     } catch (error: any) {
//       console.log(error);

//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// export const updateCartQuantity = createAsyncThunk(
//   'cart/updateCartQuantity',
//   async (item: any, thunkAPI: any) => {
//     try {
//       console.log('id', item);

//       const { userInfo } = thunkAPI.getState().auth;
//       const token = userInfo.authtoken;

//       return await cartService.updateCartQuantity(item, token);
//     } catch (error: any) {
//       console.log(error);

//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// import { uiActions } from './ui-slice';
// import { cartActions } from './cart-slice';

// export const fetchCartData = () => {
//   return async (dispatch) => {
//     const fetchData = async () => {
//       const response = await fetch(
//         'https://react-http-6b4a6.firebaseio.com/cart.json'
//       );

//       if (!response.ok) {
//         throw new Error('Could not fetch cart data!');
//       }

//       const data = await response.json();

//       return data;
//     };

//     try {
//       const cartData = await fetchData();
//       dispatch(
//         cartActions.replaceCart({
//           items: cartData.items || [],
//           totalQuantity: cartData.totalQuantity,
//         })
//       );
//     } catch (error) {
//       dispatch(
//         uiActions.showNotification({
//           status: 'error',
//           title: 'Error!',
//           message: 'Fetching cart data failed!',
//         })
//       );
//     }
//   };
// };

// export const sendCartData = (cart) => {
//   return async (dispatch) => {
//     dispatch(
//       uiActions.showNotification({
//         status: 'pending',
//         title: 'Sending...',
//         message: 'Sending cart data!',
//       })
//     );

//     const sendRequest = async () => {
//       const response = await fetch(
//         'https://react-http-6b4a6.firebaseio.com/cart.json',
//         {
//           method: 'PUT',
//           body: JSON.stringify({
//             items: cart.items,
//             totalQuantity: cart.totalQuantity,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Sending cart data failed.');
//       }
//     };

//     try {
//       await sendRequest();

//       dispatch(
//         uiActions.showNotification({
//           status: 'success',
//           title: 'Success!',
//           message: 'Sent cart data successfully!',
//         })
//       );
//     } catch (error) {
//       dispatch(
//         uiActions.showNotification({
//           status: 'error',
//           title: 'Error!',
//           message: 'Sending cart data failed!',
//         })
//       );
//     }
//   };
// };

// @-------------------------------------------------------

// TODO: Library App frontendSimplified

// const [cart, setCart] = useState([]);

// function addItemToCart(book) {
//   const dupeItem = cart.find((item) => item.id === book.id);
//   setCart((oldCart) =>
//     dupeItem
//       ? [
//           ...oldCart.map((item) => {
//             return item.id === dupeItem.id
//               ? {
//                   ...item,
//                   quantity: item.quantity + 1,
//                 }
//               : item;
//           }),
//         ]
//       : [...oldCart, { ...book, quantity: 1 }]
//   );
// }

// function updateCart(item, newQuantity) {
//   setCart((oldCart) =>
//     oldCart.map((oldItem) => {
//       if (oldItem.id === item.id) {
//         return {
//           ...oldItem,
//           quantity: newQuantity,
//         };
//       } else {
//         return oldItem;
//       }
//     })
//   );
// }

// function removeItem(item) {
//   setCart((oldCart) => oldCart.filter((cartItem) => cartItem.id !== item.id));
// }

// function numberOfItems() {
//   let counter = 0;
//   cart.forEach((item) => {
//     counter += +item.quantity;
//   });
//   return counter;
// }

// function numberOfItems() {
//   let counter = 0;
//   cart.forEach((item) => {
//     counter += +item.quantity;
//   });
//   return counter;
// }

// function calcPrices() {
//   let total = 0;
//   cart.forEach((item) => {
//     total += (item.salePrice || item.originalPrice) * item.quantity;
//   });
//   return {
//     subtotal: total * 0.9,
//     tax: total * 0.1,
//     total,
//   };
// }

// TODO: Pluralsight examples
// import * as types from "../actions/actionTypes";
// import initialState from "./initialState";

// export default function courseReducer(state = initialState.courses, action) {
//   switch (action.type) {
//     case types.CREATE_COURSE_SUCCESS:
//       return [...state, { ...action.course }];
//     case types.UPDATE_COURSE_SUCCESS:
//       return state.map(course =>
//         course.id === action.course.id ? action.course : course
//       );
//     case types.LOAD_COURSES_SUCCESS:
//       return action.courses;
//     case types.DELETE_COURSE_OPTIMISTIC:
//       return state.filter(course => course.id !== action.course.id);
//     default:
//       return state;
//   }
// }

// TODO: YOUTUBE 16 Hours

// import {
//   ADD_TO_CART,
//   REMOVE_CART_ITEM,
//   SAVE_SHIPPING_INFO,
// } from "../constants/cartConstants";
// import axios from "axios";

// // Add to Cart
// export const addItemsToCart = (id, quantity) => async (dispatch, getState) => {
//   const { data } = await axios.get(`/api/v1/product/${id}`);

//   dispatch({
//     type: ADD_TO_CART,
//     payload: {
//       product: data.product._id,
//       name: data.product.name,
//       price: data.product.price,
//       image: data.product.images[0].url,
//       stock: data.product.Stock,
//       quantity,
//     },
//   });

//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
// };

// // REMOVE FROM CART
// export const removeItemsFromCart = (id) => async (dispatch, getState) => {
//   dispatch({
//     type: REMOVE_CART_ITEM,
//     payload: id,
//   });

//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
// };

// // SAVE SHIPPING INFO
// export const saveShippingInfo = (data) => async (dispatch) => {
//   dispatch({
//     type: SAVE_SHIPPING_INFO,
//     payload: data,
//   });

//   localStorage.setItem("shippingInfo", JSON.stringify(data));
// };

// @ Reducers

// import {
//   ADD_TO_CART,
//   REMOVE_CART_ITEM,
//   SAVE_SHIPPING_INFO,
// } from '../constants/cartConstants';

// export const cartReducer = (
//   state = { cartItems: [], shippingInfo: {} },
//   action
// ) => {
//   switch (action.type) {
//     case ADD_TO_CART:
//       const item = action.payload;

//       const isItemExist = state.cartItems.find(
//         (i) => i.product === item.product
//       );

//       if (isItemExist) {
//         return {
//           ...state,
//           cartItems: state.cartItems.map((i) =>
//             i.product === isItemExist.product ? item : i
//           ),
//         };
//       } else {
//         return {
//           ...state,
//           cartItems: [...state.cartItems, item],
//         };
//       }

//     case REMOVE_CART_ITEM:
//       return {
//         ...state,
//         cartItems: state.cartItems.filter((i) => i.product !== action.payload),
//       };

//     case SAVE_SHIPPING_INFO:
//       return {
//         ...state,
//         shippingInfo: action.payload,
//       };

//     default:
//       return state;
//   }
// };

// TODO: Hitesh Choudary MERN

// export const addItemToCart = (item, next) => {
//   let cart = [];
//   if (typeof window !== undefined) {
//     if (localStorage.getItem('cart')) {
//       cart = JSON.parse(localStorage.getItem('cart'));
//     }
//     cart.push({
//       ...item,
//       count: 1,
//     });
//     localStorage.setItem('cart', JSON.stringify(cart));
//     next();
//   }
// };

// export const loadCart = () => {
//   if (typeof window !== undefined) {
//     if (localStorage.getItem('cart')) {
//       return JSON.parse(localStorage.getItem('cart'));
//     }
//   }
// };

// export const removeItemFromCart = (productId) => {
//   let cart = [];
//   if (typeof window !== undefined) {
//     if (localStorage.getItem('cart')) {
//       cart = JSON.parse(localStorage.getItem('cart'));
//     }
//     cart.map((product, i) => {
//       if (product._id === productId) {
//         cart.splice(i, 1);
//       }
//     });
//     localStorage.setItem('cart', JSON.stringify(cart));
//   }
//   return cart;
// };

// export const cartEmpty = (next) => {
//   if (typeof window !== undefined) {
//     localStorage.removeItem('cart');
//     let cart = [];
//     localStorage.setItem('cart', JSON.stringify(cart));
//     next();
//   }
// };
