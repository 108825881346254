const saveRecentLocationSearches = (data: any) => {
  const recentSearchesData = JSON.parse(
    localStorage.getItem('recentLocationSearch') as any
  );

  if (recentSearchesData === null) {
    const recentSearchesObj = { items: [] } as any;

    recentSearchesObj.items.push(data);

    localStorage.setItem(
      'recentLocationSearch',
      JSON.stringify(recentSearchesObj)
    );
  } else {
    const recentSearchesFromLocalStorage = JSON.parse(
      localStorage.getItem('recentLocationSearch') as any
    );

    let isPresent = false;
    let foundAt;
    recentSearchesFromLocalStorage.items.map(
      (item: any, index: any) => {
        for (const key in item) {
          if (item[key] === data[key]) {
            isPresent = true;
            foundAt = index;
            break;
          }
        }
      }
    );

    if (isPresent) {
      recentSearchesData.items.splice(foundAt, 1);
      recentSearchesData.items.unshift(data);
    } else {
      recentSearchesData.items.splice(4, 1);
      recentSearchesData.items.unshift(data);
    }

    localStorage.setItem(
      'recentLocationSearch',
      JSON.stringify(recentSearchesData)
    );
  }
};

export default saveRecentLocationSearches;
