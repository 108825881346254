import React from 'react';
import styled from 'styled-components';
import { QUERIES } from '../../../constants/constants';
import { NavLink, useLocation } from 'react-router-dom';
import { Award, Search, ShoppingCart, User } from 'react-feather';
import { useAppSelector } from '../../../store/hook';

const DesktopNav = () => {
  const location = useLocation();

  const { items } = useAppSelector((state) => state.cart.data);
  const cartItemsLength = Object.keys(items).length;

  return (
    <HeaderNavList>
      {location.pathname !== '/checkout' && (
        <NavListItem className="popover">
          <LinkWrapper className="popoverButton">
            <NavLink
              to="/about"
              className="headerNavLink"
              style={({ isActive }) => {
                return {
                  color: isActive ? 'var(--color-primary)' : '',
                };
              }}
            >
              <LinkIcon>
                <Search size={19} strokeWidth={2} fill="white" />
              </LinkIcon>
              <LinkText>Search</LinkText>
            </NavLink>
          </LinkWrapper>
        </NavListItem>
      )}
      {/*
      {location.pathname !== '/checkout' && (
        <NavListItem className="popover">
          <LinkWrapper className="popoverButton">
            <NavLink
              to="/spin-and-win"
              className="headerNavLink"
              style={({ isActive }) => {
                return {
                  color: isActive ? 'var(--color-primary)' : '',
                };
              }}
            >
              <LinkIcon>
                <Award size={19} strokeWidth={2} fill="white" />
              </LinkIcon>
              <LinkText>Spin & Win</LinkText>
            </NavLink>
          </LinkWrapper>
        </NavListItem>
      )} */}

      <NavListItem className="popover">
        <LinkWrapper className="popoverButton">
          <NavLink
            to="/login"
            className="headerNavLink"
            style={({ isActive }) => {
              return {
                color: isActive ? 'var(--color-primary)' : '',
              };
            }}
          >
            <LinkIcon>
              <User size={22} strokeWidth={2} fill="white" />
            </LinkIcon>
            <LinkText>Sign In</LinkText>
          </NavLink>
        </LinkWrapper>
      </NavListItem>

      {location.pathname !== '/checkout' && (
        <NavListItem className="popover">
          <LinkWrapper className="popoverButton">
            <NavLink
              to="/checkout"
              className="headerNavLink"
              style={({ isActive }) => {
                return {
                  color: isActive ? 'var(--color-primary)' : '',
                };
              }}
            >
              <LinkIcon>
                <ShoppingCart
                  size={22}
                  strokeWidth={2}
                  fill="white"
                />
              </LinkIcon>
              <LinkText>Cart</LinkText>
              <div
                style={{
                  fontSize: '40px',
                  color: 'red',
                  paddingLeft: '10px',
                  fontWeight: 'bold',
                }}
              >
                {cartItemsLength}
              </div>
            </NavLink>
          </LinkWrapper>

          <ul className="popoverPanel">
            <li>hello</li>
            <li>amit </li>
            <li>dhiman</li>
            <li>hide</li>
          </ul>
        </NavListItem>
      )}
    </HeaderNavList>
  );
};

const HeaderNavList = styled.ul`
  display: none;

  @media (min-width: 950px) {
    height: 100%;
    flex: 1;
    min-width: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* gap: 16px; */
    /* gap: clamp(1rem, 9.2vw - 4.5rem, 3.5rem); */
    margin-left: 48px;
  }
`;

const NavListItem = styled.li`
  height: 100%;
  margin-left: 32px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkIcon = styled.span`
  display: flex;
  align-items: center;
  line-height: 0;
  padding-right: 8px;
  /* position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  line-height: 0; */
`;

const LinkText = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: var(--font-weight-medium);
`;

export default DesktopNav;

{
  /* <LinkButton href="/add-transaction">
Add Transaction
</LinkButton>
<LinkButton href="/report">
View Report
</LinkButton>
<LinkButton onClick={exportData}>
Export All Data
</LinkButton> */
}

// <Popover>
//   <Popover.Button>Menu Button</Popover.Button>
//   <Popover.Panel>
//     <Nav>
//       <NavList>
//         <ListItem>
//           <NavLink to="/settings">Settings</NavLink>
//         </ListItem>
//         <ListItem>
//           <NavLink to="/settings">Orders</NavLink>
//         </ListItem>
//         <ListItem>
//           <NavLink to="/settings">Logout</NavLink>
//         </ListItem>
//       </NavList>
//     </Nav>
//   </Popover.Panel>
// </Popover>;
