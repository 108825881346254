import React, { useEffect } from 'react';
import { MapPin } from 'react-feather';
import styled from 'styled-components';
import saveRecentLocationSearches from '../../../utils/saveRecentLocationSearches';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import {
  setProceedToConfirmAddress,
  setReverseGeocodeApiData,
  setUserLocation,
  toggleSearchLocationModal,
} from '../SearchLocationSlice';
import axios from 'axios';
import userLocation from '../../../utils/userLocationUtil';
import userLocationUtil from '../../../utils/userLocationUtil';
import { placeIdGeocodeApi } from '../../../services';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

const SearchResultRow = ({ data, clearSearchSuggestions }: any) => {
  const dispatch = useAppDispatch();

  const { data: authData } = useAppSelector((state) => state.auth);
  const userInfo = authData?.userInfo;

  const getFormattedAddress = async (data: any) => {
    const apiData = await placeIdGeocodeApi(data.place_id);
    const formattedAddress = await userLocationUtil(apiData);

    return formattedAddress;
  };

  const handleSelectedAddress = async (data: any) => {
    saveRecentLocationSearches(data);

    // clearSearchSuggestions();

    if (userInfo.token) {
      const formattedAddress = await getFormattedAddress(data);

      // store this in the cookie as session
      localStorage.setItem(
        'temp_address',
        JSON.stringify(formattedAddress)
      );

      dispatch(setReverseGeocodeApiData(formattedAddress));
      dispatch(setProceedToConfirmAddress(true));
    } else {
      // call geocode api using place_id and save address address data
      // to redux and localstorage.
      dispatch(toggleSearchLocationModal(false));
      history.replaceState(null, '', '/');
      const formattedAddress = await getFormattedAddress(data);

      // IMP send address in the cookie
      cookies.set('userLocation', formattedAddress, {
        path: '/',
        expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      });

      dispatch(setUserLocation(formattedAddress));
      localStorage.setItem(
        'ymiieat_userLocation',
        JSON.stringify(formattedAddress)
      );
    }
  };

  return (
    <Wrapper onClick={() => handleSelectedAddress(data)}>
      <PinIconWrapper>
        <MapPin size={18} />
      </PinIconWrapper>
      <ResultText>
        <TopAddressText>{data?.main_text}</TopAddressText>
        <BottomAddressText>{data?.secondary_text}</BottomAddressText>
      </ResultText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 62px;
  border-bottom: 1px dashed #adadad;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }
`;

const PinIconWrapper = styled.div`
  padding-left: 6px;
  display: flex;
  align-items: center;
  height: inherit;
  line-height: 0;
`;

const ResultText = styled.div`
  font-size: 16px;
  padding-left: 16px;
  margin: auto 0px;
  font-weight: var(--font-weight-regular);
  letter-spacing: -0.1px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const TopAddressText = styled(ResultText)`
  padding-left: 0;
  font-weight: 500;
  color: rgb(30, 30, 31);
  line-height: 18px;
`;

const BottomAddressText = styled(ResultText)`
  margin-top: 4px;
  padding-left: 0;
  font-weight: 200;
  color: rgba(30, 30, 31, 0.7);
  font-size: 14px;
  line-height: 17px;
`;

export default SearchResultRow;
