import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { QUERIES } from '../../constants/constants';
import { X } from 'react-feather';
import { useAppDispatch } from '../../store/hook';
import Portal from '../Portal/Portal';
import { setLocationError } from '../../feature/SearchLocation/SearchLocationSlice';
import { useEscapeKey } from '../../hooks/useEscapeKey';

const LocationErrorPopover = ({ icon, children }: any) => {
  const dispatch = useAppDispatch();
  useEscapeKey(() => dispatch(setLocationError('')));

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setLocationError(''));
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch]);

  return (
    <Portal>
      <Wrapper>
        <LocationErrorContent>
          <IconWrapper>{icon}</IconWrapper>

          <ErrorMessage>{children}</ErrorMessage>
          <CloseButton onClick={() => dispatch(setLocationError(''))}>
            <X size={18} />
          </CloseButton>
        </LocationErrorContent>
      </Wrapper>
    </Portal>
  );
};

const WrapperMobileKeyframe = keyframes`
  0% {
      transform: translateY(100%);
  }

  100% {
      transform: translateY(0%);
  }
`;

const WrapperDesktopKeyframe = keyframes`
  0% {
      transform: translate(-50%, 100%);
  }

  100% {
      transform: translate(-50%, 0%);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 16px;
  width: 100%;
  z-index: 99999999999999999999999;
  /* cubic-bezier(0.1, 0.8, 0.2, 1) */
  /* animation: 500ms ease 0ms 1 normal none running
    ${WrapperMobileKeyframe}; */
  /* transition: transform 500ms ease-in-out 0s;
  will-change: transform;
  backface-visibility: hidden; */

  @media (${QUERIES.tabletAndUp}) {
    bottom: 16px;
    left: 50%;
    max-width: 500px;
    transform: translateX(-50%);
    animation: 350ms ease ${WrapperDesktopKeyframe};
  }
`;

const LocationErrorContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  margin: 0px 16px;
  max-height: 100vh;
  border-radius: 16px;
  background-color: #000000;
  color: white;
  box-shadow: rgba(27, 30, 36, 0.1);
  /* width: 100%; */
`;

const IconWrapper = styled.div`
  & > svg {
    display: block;
  }
`;

const ErrorMessage = styled.div`
  font-size: 13px;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  /* align-self: ; */
  padding: 2px;
  border-radius: 50%;
  background-color: white;

  & > svg {
    display: block;
    color: black;
  }
`;

export default LocationErrorPopover;
