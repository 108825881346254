import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import TextInput from '../TextInput';
import PhoneInput from '../PhoneInput';
import { QUERIES } from '../../constants/constants';
import { emailLoginApi } from '../../services/auth-service';
import { useAppDispatch } from '../../store/hook';
import { setUserData } from '../../feature/auth/authSlice';

const cookies = new Cookies();

let currentOtpIndex = 0;
const LoginUsingEmail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] =
    useState('');
  const [password, setPassword] = useState('');
  const [passValidationMessage, setPassValidationMessage] =
    useState('');
  const [loginApiErrorMessage, setLoginApiErrorMessage] =
    useState('');
  const [formError, setformError] = useState(false);

  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [showOtp, setShowOtp] = useState(false);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);

  const inputRef: any = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  const handleOnChange = (event: any, index: any) => {
    const value = event?.target.value;
    if (isNaN(value)) return false;

    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }

    setOtp(newOtp);
  };

  const handleOnKeyDown = (event: any, index: any) => {
    currentOtpIndex = index;

    if (event.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowLeft') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowRight') {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
  };

  const validateEmail = function () {
    const emailRegex = new RegExp(
      '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );
    if (!emailRegex.test(email) || email === '') {
      setformError(true);
      setEmailValidationMessage('Enter your email address');
    } else {
      setformError(false);
      setEmailValidationMessage('');
    }
  };

  const validatePassword = function () {
    const passRegex = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
    );
    if (!passRegex.test(password) || password === '') {
      setPassValidationMessage('Invalid or incorrect password');
      setformError(true);
    } else {
      setPassValidationMessage('');
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    validateEmail();
    validatePassword();
    console.log(formError);

    if (
      !formError &&
      !emailValidationMessage &&
      !passValidationMessage
    ) {
      console.log('user is signed in');

      signIn();
    }
  };

  const signIn = async () => {
    const payload = {
      email: email,
      password: password,
    };

    try {
      const response = await emailLoginApi(payload);
      if (response.data.success) {
        cookies.set('token', response?.data?.data.token, {
          path: '/',
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          // httpOnly: true,
          // secure: true,
        });

        dispatch(setUserData(response.data.data));

        navigate('/');
      }

      console.log('response', response);
    } catch (error: any) {
      setPassValidationMessage('Invalid or incorrect password');
      setLoginApiErrorMessage(error.response.data.message);
    }
  };

  return (
    <LoginWrapper>
      <LoginText>Login</LoginText>
      <SubTitle>Enter your Email address to continue</SubTitle>

      <AuthForm>
        <TextInput
          errorMsg={emailValidationMessage}
          required={true}
          label={
            emailValidationMessage ? 'Invalid email address' : 'Email'
          }
          type="email"
          value={email}
          onBlur={() => {
            if (email) {
              validateEmail();
            }
          }}
          onChange={(event: any) => {
            setEmailValidationMessage('');
            setEmail(event.target.value);
          }}
          autoFocus={true}
          autoComplete="off"
          maxLength={100}
        />

        <TextInput
          errorMsg={passValidationMessage}
          required={true}
          label={
            passValidationMessage ? passValidationMessage : 'Password'
          }
          type="password"
          onBlur={() => {
            if (password) {
              validatePassword();
            }
          }}
          value={password}
          onChange={(event: any) => {
            setPassValidationMessage('');
            setPassword(event.target.value);
          }}
          autoComplete="off"
          maxLength={100}
        />

        {/* {loginApiErrorMessage && (
          <LoginErrorMessage>
            {loginApiErrorMessage}
          </LoginErrorMessage>
        )} */}

        <SubmitButton onClick={handleSubmit}>Login</SubmitButton>
      </AuthForm>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div``;

const LoginText = styled.h4`
  font-size: 20px;
  margin: 2px 0 8px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin: 0%;
  color: #707579;
  line-height: 1.3125;
  text-align: center;
`;

const AuthForm = styled.form`
  /* flex: 0; */
  height: 100%;
  width: 100%;
  margin-top: 40px;
  padding: 0px 16px;

  @media ${QUERIES.tabletAndUp} {
    padding: 0;
  }
`;

const SubmitButton = styled.button`
  margin-top: 24px;
  border-radius: 14px;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  background-color: black;
  color: white;
  height: 48px;
  text-align: center;
`;

export default LoginUsingEmail;
