import React from 'react';

export function useEscapeKey(callback: any) {
  React.useEffect(() => {
    function handleKeyDown(event: any) {
      if (event.code === 'Escape') {
        callback();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [callback]);
}
