import { useLoadScript } from '@react-google-maps/api';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import GoogleMapContainer from './AddressGoogleMapContainer';
import { Loader } from 'react-feather';
import AddressGoogleMapContainer from './AddressGoogleMapContainer';

const Map = ({ maxWidth }: any) => {
  const { isLoaded, loadError } = useLoadScript({
    id: 'script-loader',
    // googleMapsApiKey: '',
    googleMapsApiKey: 'AIzaSyDPYYANdDK_TJg9bTk9bop0hJQNuVO4cfI',
  });

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <AddressGoogleMapContainer maxWidth={maxWidth} />
  ) : (
    <MapShimmer>
      <LoaderIconWrapper>
        <Loader />
      </LoaderIconWrapper>
    </MapShimmer>
  );
};

const SpinKeyframe = keyframes`
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
`;

const MapShimmer = styled.div`
  width: 100%;
  height: 400px;
  background-color: hsl(0, 0%, 96%);
  position: relative;
`;

const LoaderIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: ${SpinKeyframe} 1000ms linear infinite;
`;

export default React.memo(Map);
