import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronRight, Navigation } from 'react-feather';
import styled, { keyframes } from 'styled-components';
import { QUERIES } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import Spacer from '../../Spacer/Spacer';
import SearchLocation from '../../../feature/SearchLocation/SearchLocation';
import {
  setProceedToConfirmAddress,
  showLocationPopover,
  toggleSearchLocationModal,
} from '../../../feature/SearchLocation/SearchLocationSlice';
import useGetLocalStorage from '../../../hooks/useGetLocalStorage';
import LocationPopover from './LocationPopover';
import Portal from '../../Portal/Portal';
import Modal from '../../Modal/Modal';
import { useNavigate } from 'react-router-dom';
import { ELEVATIONS } from '../../../constants/elevations';

const HeaderLocation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [value] = useGetLocalStorage('ymiieat_userLocation');

  const { data, isSearchLocationModalOpen, isLocationPopoverOpen } =
    useAppSelector((state) => state.searchLocation);
  const userLocation = data.userLocation;

  return (
    <>
      <DesktopWrapper
        onClick={() => dispatch(toggleSearchLocationModal(true))}
      >
        <NavIconWrapper>
          <Navigation size={18} color="#a50000" fill="#a50000" />
        </NavIconWrapper>
        <Spacer x={12} />
        {userLocation || value ? (
          <>
            <DeliveryLocationText>
              {userLocation?.deliveryLocation ||
                value?.deliveryLocation}
              .
            </DeliveryLocationText>
            <AddressText>
              {userLocation?.address || value?.address}.
            </AddressText>
            <ChangeText>Change</ChangeText>
            <ChevronIconWrapper>
              <ChevronDown size={20} color="#a50000" />
            </ChevronIconWrapper>
          </>
        ) : (
          <>
            <DeliveryText>Enter Your Delivery Address</DeliveryText>
            <Spacer x={6} />
            <ChevronIconWrapper>
              <ChevronDown size={20} color="#a50000" />
            </ChevronIconWrapper>

            {/* // TODO: uncomment this later */}
          </>
        )}
      </DesktopWrapper>

      <MobileWrapper
        onClick={() => {
          dispatch(toggleSearchLocationModal(true));
          history.pushState({ urlPath: '/' }, '', '/set-location');
        }}
      >
        <NavIconWrapper>
          <Navigation size={18} color="#a50000" fill="#a50000" />
        </NavIconWrapper>
        <TextWrapper>
          {userLocation || value ? (
            <>
              {userLocation?.deliveryLocation ||
                value?.deliveryLocation}
              .{' '}
              <span style={{ marginLeft: '4px' }}>
                {userLocation?.address || value?.address}
              </span>
            </>
          ) : (
            <>
              <span> Enter your precise location</span>

              {/* // TODO: uncomment this later */}
            </>
          )}
        </TextWrapper>

        {(userLocation || value) && (
          <ChangeButton>Change</ChangeButton>
        )}
        <ChevronIconWrapper>
          <ChevronRight size={19} color="#a50000" />
        </ChevronIconWrapper>
      </MobileWrapper>

      {isLocationPopoverOpen && (
        <LocationPopOverWrapper
          onClick={() => {
            dispatch(toggleSearchLocationModal(true));
            dispatch(showLocationPopover(false));
            history.pushState(null, '', '/set-location');
          }}
        >
          <LocationPopover
            closePopover={() => dispatch(showLocationPopover(false))}
          />
        </LocationPopOverWrapper>
      )}

      {isSearchLocationModalOpen && (
        <Modal
          // heading="Search your location"
          hideCloseButtonOnMobile={true}
          handleDismiss={() => {
            dispatch(toggleSearchLocationModal(false));
            dispatch(setProceedToConfirmAddress(false));
          }}
        >
          <SearchLocationWrapper>
            <SearchLocation />
          </SearchLocationWrapper>
        </Modal>
      )}
    </>
  );
};

// /////////////////////////////////////
const DesktopWrapper = styled.button`
  display: none;

  @media ${QUERIES.tabletAndUp} {
    margin: 0;
    padding: 8px 16px;
    border: none;
    background: hsl(0, 0%, 95.33%);
    color: inherit;
    font-size: 16px;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    height: 48px;
    max-width: 400px;
    outline: none;
    position: relative;
    border-radius: 12px;
    background-color: hsl(0deg 100% 98.35%); // red shade
    --shadow-color: 0deg 100% 38.35%;
    box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));
  }

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }
`;

const PinIconWrapper = styled.div``;

const DeliveryText = styled.span`
  color: #000000;
  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);
`;

const DeliveryLocationText = styled.span`
  color: #000000;
  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);
  /* display: block; */
  text-overflow: ellipsis;
  white-space: nowrap;
  // overflow: hidden;
  // max-width: 100px;
`;

const AddressText = styled.span`
  color: #000000;
  font-size: 14px;
  /* display: block; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* max-width: 100px; */
  padding: 0 10px;
`;

const ChangeText = styled.span`
  color: #a50000;
  font-size: 14px;
  margin-right: 4px;
  // text-decoration: 2px solid #f7b500 underline;
  /* display: block; */

  &:hover {
    text-decoration: 2px solid #a50000 underline;
  }
`;

// Mobile styles
const MobileWrapper = styled.button`
  margin: 0;
  margin-top: 4px;
  padding: 0 8px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  color: inherit;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 48px;
  /* background-color: hsl(43.96, 100%, 98.43%); //yellow */
  background-color: hsl(0deg 100% 98.35%);
  --shadow-color: 0deg 100% 38.35%;
  box-shadow: 0px 2px 2px -2px hsl(var(--shadow-color));
  border-radius: 12px;
  width: 100%;
  position: relative;

  &:hover {
    box-shadow: 0px 2px 4px -2px hsl(var(--shadow-color)),
      0px 0px 4px -2px hsl(var(--shadow-color));
  }

  @media ${QUERIES.tabletAndUp} {
    display: none;
    width: revert;
    border-top: none;
  }
`;

const NavIconWrapper = styled.div`
  margin-right: 8px;
  line-height: 0;
`;

const TextWrapper = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.3px;
  /* width: 100%; */
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-right: 8px;
`;

const ChangeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: hsl(120, 100%, 99%);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.3px;
  color: #a50000;
  margin-left: auto;
  padding: 6px;
  /* letter-spacing: -0.3px; */

  &:hover {
    text-decoration: underline 2px solid #a50000;
  }
`;

const ChevronIconWrapper = styled.div`
  margin-left: auto;
  /* margin-left: 10px; */
  line-height: 0;
`;

const SearchLocationWrapper = styled.div`
  padding: 16px;

  @media (${QUERIES.tabletAndUp}) {
    padding: 32px;
  }
`;

const LocationPopOverWrapper = styled.div``;

export default React.memo(HeaderLocation);
