import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from 'styled-components';
import TextInput from '../TextInput';
import PhoneInput from '../PhoneInput';
import { QUERIES } from '../../constants/constants';
import { otpVerifyApi, signupApi } from '../../services/auth-service';
import { setUserData } from '../../feature/auth/authSlice';
import { useAppDispatch } from '../../store/hook';
import WelcomeModal from '../WelcomeModal/WelcomeModal';

const cookies = new Cookies();

let currentOtpIndex = 0;
const SignUp = ({ mobileNumber }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [phoneNumber, setPhoneNumber] = useState(
    mobileNumber ? mobileNumber : ''
  );
  const [phoneValidationMessage, setPhoneValidationMessage] =
    useState('');
  const [name, setName] = useState('');
  const [nameValidationMessage, setNameValidationMessage] =
    useState('');
  const [email, setEmail] = useState('');
  const [emailValidationMessage, setEmailValidationMessage] =
    useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpValidationMessage, setOtpValidationMessage] =
    useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [setWelcomeModalOpen, isSetWelcomeModalOpen] =
    useState(false);

  const inputRef: any = useRef(null);

  console.log('email', email);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  const handleOnChange = (event: any, index: any) => {
    const value = event?.target.value;
    if (isNaN(value)) return false;

    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }

    setOtp(newOtp);
  };

  const handleOnKeyDown = (event: any, index: any) => {
    currentOtpIndex = index;

    if (event.key === 'Backspace') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowLeft') {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
    if (event.key === 'ArrowRight') {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
  };

  const validatePhoneNumber = function () {
    const phoneRegex = new RegExp('^[6-9][0-9]{9}');
    if (!phoneRegex.test(phoneNumber) || phoneNumber === '') {
      setPhoneValidationMessage('Enter phone number');
    } else {
      setPhoneValidationMessage('');
    }
  };

  const validateEmail = function () {
    const emailRegex = new RegExp(
      '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$'
    );
    if (!emailRegex.test(email) || email === '') {
      setEmailValidationMessage('Enter your email address');
    } else {
      setEmailValidationMessage('');
    }
  };

  const validateName = function () {
    if (!name) {
      setNameValidationMessage('Enter your name');
    } else {
      setNameValidationMessage('');
    }
  };

  const signup = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      name: name,
      email: email.toLowerCase(),
    };

    try {
      const response = await signupApi(payload);
      if (response.data.success) {
        setShowOtp(true);
      }
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
      setShowOtp(false);
      if (!error.response.data.success) {
        navigate('/signup');
      }
    }
  };

  const otpVerify = async () => {
    const payload = {
      phoneNumber: phoneNumber,
      otp: otp.join(''),
    };

    try {
      const response = await otpVerifyApi(payload);
      if (response.data.success) {
        console.log(
          '🚀 ~ file: SignUp.tsx:133 ~ otpVerify ~ response.data:',
          response.data.name
        );
        cookies.set('token', response?.data?.data.token, {
          path: '/',
          expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
          // httpOnly: true,
          // secure: true,
        });

        dispatch(setUserData(response.data.data));

        isSetWelcomeModalOpen(true);
        // navigate('/');
      }
    } catch (error: any) {
      setOtpValidationMessage(error.response.data.message);
    }
  };

  const handleSendOtp = (event: any) => {
    event.preventDefault();
    validatePhoneNumber();
    validateEmail();
    validateName();

    if (
      !phoneValidationMessage &&
      !nameValidationMessage &&
      !emailValidationMessage &&
      phoneNumber &&
      name &&
      email
    ) {
      signup();
    }
  };

  const handleVerifyOtp = (event: any) => {
    event.preventDefault();
    otpVerify();
  };

  return (
    <SignupWrapper>
      <LoginText>Sign up</LoginText>
      <SubTitle>Create your account</SubTitle>

      <AuthForm>
        <PhoneInput
          errorMsg={phoneValidationMessage}
          required={true}
          label={
            phoneValidationMessage
              ? phoneValidationMessage
              : 'Phone number'
          }
          type="tel"
          value={phoneNumber}
          onChange={(event: any) => {
            if (isNaN(event.target.value)) return false;
            setPhoneValidationMessage('');
            setPhoneNumber(event.target.value);
          }}
          onBlur={() => {
            if (phoneNumber) {
              validatePhoneNumber();
            }
          }}
          autoComplete="on"
          autoFocus={true}
          maxLength={10}
        />

        {!showOtp && (
          <>
            <TextInput
              errorMsg={nameValidationMessage}
              required={true}
              label={
                nameValidationMessage ? 'Enter your name' : 'Name'
              }
              type="text"
              value={name}
              onBlur={() => {
                if (name) {
                  validateName();
                }
              }}
              onChange={(event: any) => {
                setNameValidationMessage('');
                setName(event.target.value);
              }}
              autoComplete="off"
              maxLength={30}
            />

            <TextInput
              errorMsg={emailValidationMessage}
              required={true}
              label={
                emailValidationMessage
                  ? emailValidationMessage
                  : 'Email'
              }
              type="email"
              value={email.toLowerCase()}
              onBlur={() => {
                if (email) {
                  validateEmail();
                }
              }}
              onChange={(event: any) => {
                setEmailValidationMessage('');
                setEmail(event.target.value.toLowerCase());
              }}
              autoComplete="off"
              maxLength={100}
            />
          </>
        )}

        {showOtp && (
          <OtpWrapper>
            {otpValidationMessage && otpValidationMessage}
            <OtpInnerWrapper>
              <InputsWrapper>
                {otp.map((_: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <Input
                        ref={
                          index === activeOtpIndex ? inputRef : null
                        }
                        autoFocus={
                          index === activeOtpIndex ? inputRef : null
                        }
                        type="number"
                        inputMode="numeric"
                        maxLength={1}
                        pattern="[a-zA-Z0-9]{1}"
                        value={otp[index]}
                        onChange={(event) =>
                          handleOnChange(event, index)
                        }
                        onKeyDown={(event) =>
                          handleOnKeyDown(event, index)
                        }
                        onFocus={(e) => e.target.select()}
                      />
                    </React.Fragment>
                  );
                })}
              </InputsWrapper>
            </OtpInnerWrapper>
          </OtpWrapper>
        )}

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <SubmitButton
          onClick={showOtp ? handleVerifyOtp : handleSendOtp}
        >
          {showOtp ? 'VERIFY OTP' : 'CONTINUE'}
        </SubmitButton>
      </AuthForm>

      {setWelcomeModalOpen && <WelcomeModal />}
    </SignupWrapper>
  );
};

const SignupWrapper = styled.div``;

const LoginText = styled.h4`
  font-size: 20px;
  margin: 2px 0 8px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  margin: 0%;
  color: #707579;
  line-height: 1.3125;
  text-align: center;
`;

const ErrorMessage = styled(SubTitle)`
  text-align: start;
  margin-top: 16px;
  margin-left: 20px;
  color: #d40000;
  font-weight: 600;
  font-size: 16px;
`;

const AuthForm = styled.form`
  /* flex: 0; */
  height: 100%;
  width: 100%;
  margin-top: 40px;
  padding: 0px 16px;

  @media ${QUERIES.tabletAndUp} {
    padding: 0;
  }
`;

const OtpWrapper = styled.div`
  text-align: center;
  font-size: 20px;
  margin-top: 16px;
  padding: 7px 6px;
`;

const OtpInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Input = styled.input`
  height: 48px;
  /* width: 48px; */
  width: 15%;
  padding: 0px;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  /* color: rgb(28, 28, 28); */
  color: #1c0080;
  border: 2px solid rgb(207, 207, 207);
  border-radius: 14px;
  appearance: none;
  outline: none;
  appearance: none;
  outline: none;
  transition: all 250ms;
  border: ${(prop: any) => prop.value && '2px solid #9370DB'};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    border: 2px solid #6226d8;
  }
`;

const SubmitButton = styled.button`
  margin-top: 24px;
  border-radius: 14px;
  font-weight: 600;
  border: none;
  appearance: none;
  outline: none;
  width: 100%;
  /* background-color: #7c4ddb; */
  background-color: green;
  color: white;
  height: 48px;
  text-align: center;
`;

export default SignUp;
