import { useEffect, useState } from 'react';

const useGetLocalStorage = (key: any) => {
  const [value, setValue] = useState(() =>
    JSON.parse(localStorage.getItem(key) as any)
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useGetLocalStorage;

// function SaveValues() {
//   const [value, setValue] = useLocalStorage('name', '');
//   return <input value={value} onChange={(e) => setValue(e.target.value)} />;
// }

// A value can be added to localStorage with window.localStorage.setItem(key, value), and that value can be accessed with window.localStorage.getItem(key).
