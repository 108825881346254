import React, { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { reverseGeocode } from '../feature/SearchLocation/SearchLocationThunk';
import { createToast } from '../feature/toaster/ToastSlice';
import {
  setLocationError,
  toggleSearchLocationModal,
} from '../feature/SearchLocation/SearchLocationSlice';

const options = {
  enableHighAccuracy: true,
};

const useGeoLocation = () => {
  const dispatch = useAppDispatch();
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [address, setAddress] = useState('');

  const error = useCallback(() => {
    console.log('Error in useGeolocation');

    setIsError(true);
    setMessage('Allow your location to know Delivery Service.');
    dispatch(
      setLocationError('You denined us access to your location.')
    );
  }, [dispatch]);

  const fetchLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setIsError(false);
          setIsSuccess(true);
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          console.log('fetch callled');

          dispatch(
            reverseGeocode({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          );
          setIsSuccess(true);
        },
        error,
        options
      );
    } else {
      setMessage('Browser does not support GeoLocation');
    }
  }, [dispatch, error]);

  return { coordinates, fetchLocation, message, isSuccess, isError };
};

export default useGeoLocation;

// import { useState, useEffect } from "react";
// import Geolocation from "@react-native-community/geolocation";
